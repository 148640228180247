
function calcFrameScale( fwidth, fheight, validIds, layersCfg )
{
    let x0 = 1e10
    let y0 = 1e10 
    let x1 = -1e10
    let y1 = -1e10

    for( let layer_id of validIds )
    {
        let location = layersCfg.current[layer_id].location

        if( location.x < x0 )
        {
            x0 = location.x 
        }

        if( location.y < y0 )
        {
            y0 = location.y 
        }

        if( location.x + location.width > x1 )
        {
            x1 = location.x  + location.width
        }

        if( location.y + location.height > y1 )
        {
            y1 = location.y + location.height
        }
    }

    //let fwidth = canvasWidth.current
    //let fheight = canvasHeight.current 
    let x = x0 
    let y = y0 
    let width = x1 - x0 
    let height = y1 - y0

    var scale = Math.min( fwidth/width, fheight/height );
    var wdiff = Math.abs( fwidth - width*scale );
    var hdiff = Math.abs( fheight - height*scale );

    let frame = {}
    frame['scale'] = scale 
    frame['x0'] = x0 
    frame['y0'] = y0

    frame['x_offset'] = wdiff/2;
    frame['y_offset'] = hdiff/2;

    return frame
}


function scaleLocation( location, frame )
{
    let x = (location.x - frame.x0) * frame.scale + frame.x_offset 
    let y = (location.y - frame.y0) * frame.scale + frame.y_offset
    let width = location.width * frame.scale 
    let height = location.height * frame.scale 

    return { x,y,width,height }
}


function drawText(ctx, text, rect) {
    ctx.fillStyle = "white";
    ctx.font = "12px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(text, rect.x + rect.width / 2, rect.y + rect.height / 2);
}

function drawOtherLayer(ctx, config, imageRect) {
    const colors = { avatar: "blue", emoticon: "red", text: "green", dyno: "orange" };
    ctx.fillStyle = colors[config.info.type] || "black";
    ctx.fillRect(imageRect.x, imageRect.y, imageRect.width, imageRect.height);
    
    let text = config.place_holder && config.place_holder !== "" ? config.place_holder : config.info.type;
    drawText(ctx, text.charAt(0).toUpperCase() + text.slice(1), imageRect);
}

export async function widgetCapture(width, height, layers, images, layersCfg) {
    let validIds = [];

    for (let layer of layers) {
        if (layersCfg.current[layer.id] == null) {
            continue;
        }
        validIds.unshift(layer.id);
    }

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    let frameScale = calcFrameScale(width, height, validIds, layersCfg);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let layer_id of validIds) {
        let config = layersCfg.current[layer_id];
        let location = scaleLocation(config.location, frameScale);

        if (config.info.type === "image" && images[layer_id]) {
            let image = images[layer_id];
            ctx.drawImage(image, location.x, location.y, location.width, location.height);
        } else {
            drawOtherLayer(ctx, config, location);
        }
    }

    let pngData = canvas.toDataURL().split(';base64,')[1];
    return pngData;
}