import { useState, useEffect } from "react"

import { backendAccessPost, backendAccessGet, backendMe, logout } from '../Utils/Authentication'
import ReactMarkdown from 'react-markdown'
import tosFrPath from './tos20240614.md'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


export default function TermsOfUse(props) {

    const userConfig = props.userConfig
    const setUserConfig = props.setUserConfig

    const [showModal, setShowModal] = useState(false)
    const [ markdown, setMarkdown ] = useState("")

    useEffect(() => {
        if( userConfig.terms_of_use == null )
        {
            setShowModal(true)
            return 
        }
        
        if( userConfig.terms_of_use.accepted == null )
        {
            setShowModal(true)
            return
        }
    }, [ userConfig ]

    )
    const accept = () => {
        backendAccessGet('/api/state/accept-terms-of-use/').then(res => {
            if (res != null) {
                setShowModal(false)
                
                let c = structuredClone(userConfig)
                c.terms_of_use = res.terms_of_use
                setUserConfig(c)
            }

        })
    }

    useEffect( () => {
        if( showModal )
        {
            fetch(tosFrPath)
                .then((res) => res.text())
                .then((text) => setMarkdown(text));
        }
    },[showModal])

    return (
        <>
            {props.children}
            <Modal size="lg" show={showModal}>
                <Modal.Header>
                    <Modal.Title style={{ width: '100%' }}>
                        <div className="d-flex justify-content-center" 
                             style={{ width:'100%'}}>
                            <img src={'/images/logo-motion-0205_1.gif'} 
                                 style={{ width: 100, height: 'auto', bottom: '5px', 
                                          paddingTop: '10px' }} 
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '350px', width: '100%', overflowY:'scroll', fontSize:'12px' }}>
                    <ReactMarkdown>
                        {markdown}
                    </ReactMarkdown>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                        <Button variant="dark" onClick={accept}>Accept</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}


