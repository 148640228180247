import { useEffect, useState } from "react"
import DNDList from "../Misc/DNDList"

const bosses = [
    {
        name : "Genichiro Ashina",
        type : "Boss",
        id : "14"
    },

    {
        name : "Gyoubu Oniwa",
        type : "Boss",
        id : "0"
    },
    {
        name : "Lady Butterfly",
        type : "Boss",
        id : "1"
    },
    {
        name : "Folding Screen Monkeys",
        type : "Boss",
        id : "2"
    },
    {
        name : "Guardian Ape",
        type : "Boss",
        id : "3"
    },
    {
        name : "Guardian Ape (Headless)",
        type : "Boss",
        id : "4"
    },
    {
        name : "Corrupted Monk (Illusion)",
        type : "Boss",
        id : "5"
    },
    {
        name : "Isshin Ashina",
        type : "Boss",
        id : "6"
    },
    {
        name : "Great Shinobi Owl",
        type : "Boss",
        id : "7"
    },
    {
        name : "Corrupted Monk",
        type : "Boss",
        id : "8"
    },
    {
        name : "Divine Dragon",
        type : "Boss",
        id : "9"
    },
    {
        name : "Owl (Father)",
        type : "Boss",
        id : "10"
    },
    {
        name : "Demon of Hatred",
        type : "Boss",
        id : "11"
    },
    {
        name : "Isshin, the Sword Saint",
        type : "Boss",
        id : "12"
    },
    {
        name : "Genichiro, Way of Tomoe",
        type : "Boss",
        id : "13"
    },
    {
        name : "Leader Shigenori Yamauchi",
        type : "Miniboss",
        id : "15"
    },
    {
        name : "General Naomori Kawarada",
        type : "Miniboss",
        id : "16"
    },
    {
        name : "Chained Ogre",
        type : "Miniboss",
        id : "17"
    },
    {
        name : "General Tenzen Yamauchi",
        type : "Miniboss",
        id : "18"
    },
    {
        name : "Shinobi Hunter Enshin of Misen",
        type : "Miniboss",
        id : "19"
    },
    {
        name : "Juzou the Drunkard",
        type : "Miniboss",
        id : "20"
    },
    {
        name : "Blazing Bull",
        type : "Miniboss",
        id : "21"
    },
    {
        name : "General Kuranosuke Matsumoto",
        type : "Miniboss",
        id : "22"
    },
    {
        name : "Shikibu Toshikatsu Yamauchi",
        type : "Miniboss",
        id : "23"
    },
    {
        name : "Lone Shadow Longswordsman",
        type : "Miniboss",
        id : "24"
    },
    {
        name : "Ashina Elite - Jinsuke Saze",
        type : "Miniboss",
        id : "25"
    },
    {
        name : "Headless",
        type : "Miniboss",
        id : "26"
    },
    {
        name : "Great Serpent",
        type : "Miniboss",
        id : "27"
    },
    {
        name : "Long-arm Centipede Giraffe",
        type : "Miniboss",
        id : "28"
    },
    {
        name : "Snake Eyes Shirahagi",
        type : "Miniboss",
        id : "29"
    },
    {
        name : "Tokujiro the Glutton",
        type : "Miniboss",
        id : "30"
    },
    {
        name : "Mist Noble",
        type : "Miniboss",
        id : "31"
    },
    {
        name : "O'Rin of the Water",
        type : "Miniboss",
        id : "32"
    },
    {
        name : "Shichimen Warrior",
        type : "Miniboss",
        id : "33"
    },
    {
        name : "Lone Shadow Vilehand",
        type : "Miniboss",
        id : "34"
    },
    {
        name : "Lone Shadow Masanaga the Spear-Bearer",
        type : "Miniboss",
        id : "35"
    },
    {
        name : "Armored Warrior",
        type : "Miniboss",
        id : "36"
    },
    {
        name : "Long Arm Centipede Sen-Un",
        type : "Miniboss",
        id : "37"
    },
    {
        name : "Snake Eyes Shirafuji",
        type : "Miniboss",
        id : "38"
    },
    {
        name : "Sakura Bull of the Palace",
        type : "Miniboss",
        id : "39"
    },
    {
        name : "Okami Leader Shizu",
        type : "Miniboss",
        id : "40"
    },
    {
        name : "Great Colored Carp",
        type : "Miniboss",
        id : "41"
    },
    {
        name : "Seven Ashina Spears - Shume Masaji Oniwa",
        type : "Miniboss",
        id : "42"
    },
    {
        name : "Ashina Elite Ujinari Mizuo",
        type : "Miniboss",
        id : "43"
    },
    {
        name : "Shigekichi of the Red Guardian",
        type : "Miniboss",
        id : "44"
    }
]

export function Item( props )
{
    const data = props.data 
    const onClick = props.onClick

    const onRemove = props.onRemove

    const [ isHover, setIsHover ] = useState(false)

    return (
        <div className="d-flex justify-content-start align-items-center" 
             style={{ width:'100%', height:'30px', backgroundColor:'#DADADA',
                      borderRadius:'2px', marginBottom:'10px', padding:'5px',
                      border: (isHover) ? '0.2px solid #000000':'',
                      boxShadow:(isHover) ? '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)': '',
                      cursor:'pointer'}}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
             onClick={onClick}
        >
            <>
            {data.type} / <b>{ data.name }</b>
            </>
            { onRemove &&
            <>
            [<a href="#" onClick={onRemove}>Remove</a>]
            </>
            }
            
        </div> 
    )
}

export function ItemDnd( props )
{
    const data = props.item
    const index = props.index
    const onRemove = props.params.onRemove

    const [ isHover, setIsHover ] = useState(false)

    return (
        <div className="d-flex justify-content-start align-items-center" 
             style={{ width:'100%', height:'30px', backgroundColor:'#DADADA',
                      borderRadius:'2px', marginBottom:'10px', padding:'5px',
                      border: (isHover) ? '0.2px solid #000000':'',
                      boxShadow:(isHover) ? '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)': '',
                      cursor:'grab'}}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
        >
            <>
            {data.type} / <b>{ data.name }</b>
            </>
            [<a href="#" onClick={ e => onRemove(index)} style={{ cursor:'pointer'}}>Remove</a>]
        </div> 
    )
}

export default function SekiroCfg( props )
{
    const modelCfg = props.modelCfg 
    const setModelCfg = props.setModelCfg
    const reset = props.reset

    const [ selected, setSelected ] = useState([])


    useEffect( () => {
        if( modelCfg.boss_selection != null )
        {
            setSelected( modelCfg.boss_selection )
        }
        else 
        {
            setSelected([])
        }
    },[ reset ])

    useEffect( () => {
        let c = structuredClone(modelCfg)
        c.boss_selection = selected 
        setModelCfg(c)

    },[selected])

    const add = (item) => {
        for( const s of selected )
        {
            if( s.name == item.name && s.type == item.type )
            {
                return
            }
        }

        let ss = structuredClone(selected)
        ss.push( item )
        setSelected(ss)
    }
    
    const reorder = (list, startIndex, endIndex) => {
        var l = structuredClone(selected)

        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setSelected(l)
    }


    const remove = (idx) => {
        let ss = structuredClone(selected)
        ss.splice(idx,1)
        setSelected(ss)
    }

    return (
        <div style={{ width:'100%', height:'100%', fontSize:'12px'}}>
            <div style={{ width:'50%', height:'100%', float:'left',
                          padding:'10px', overflowY:'scroll'}}>
                <div>
                    All bosses
                </div>
                { bosses.map( (b,idx) => 
                <Item data={b} key={idx} onClick={ e => add(b)}/> 
                )}
            </div>
            <div style={{ width:'50%', height:'100%', float:'left',
                          padding:'10px', overflowY:'scroll'}}>
                <div>
                    Narrative
                </div>

                <DNDList items={selected}
                         reorder={reorder}
                         direction="vertical"
                         Inner={ItemDnd}
                         innerParams={{ 'onRemove':remove }}
                />
            </div>
        </div>
    )
}
