import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"
import CoordinateModule from "../Modules/CoordinateModule"

const avatarVariables = [
    {name:'Value', key:'value', type:'R'},
]


export default function AvatarProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType
    const reset = props.reset

    const [ name, setName ] = useState(null)
    const [ source, setSource ] = useState(null)
    const [ context, setContext ] = useState(null)
    const [ feed, setFeed ] = useState(null)
    const [ rank, setRank ] = useState(null)
    const [ hashtag, setHashtag ] = useState(null)
    const [ twitchFallback, setTwitchFallback ] = useState(null)
    const [ variables, setVariables ] = useState(null)
    const [ coordinates, setCoordinates ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        let properties = layersCfg.current[selected].properties

        if( properties == null )
        {
            properties = {}
        }

        let n = layersCfg.current[selected].name 
        setName( (n) ? n : "" )
        setSource( (properties.source) ? properties.source : "streamer")
        setContext( (properties.context) ? properties.context : "username")
        setFeed( (properties.feed) ? properties.feed : "username" )
        setRank( (properties.rank) ? properties.rank : 0 )
        setHashtag( (properties.hashtag) ? properties.hashtag : "")
        setTwitchFallback( (properties.twitch_fallback) ? properties.twitch_fallback : false )
        setVariables( (properties.variables) ? properties.variables : {} )

        let c = {}
        c.location = layersCfg.current[selected].location
        c.fixed_aspect_ratio = layersCfg.current[selected].fixed_aspect_ratio

        setCoordinates(c)
    }

    useEffect( () => {
        reload()
    },[reset])

    useEffect( () => {
        if( source == null || context == null || feed == null || rank == null || 
            hashtag == null || variables == null || name == null || coordinates == null || twitchFallback == null )
        {
            return
        }

        var data = {
            source: source,
            context: context,
            feed: feed,
            rank: rank,
            hashtag: hashtag,
            twitch_fallback: twitchFallback,
            variables: variables
        }

        layersCfg.current[selected].name = name 
        layersCfg.current[selected].location = coordinates.location
        layersCfg.current[selected].fixed_aspect_ratio = coordinates.fixed_aspect_ratio
        layersCfg.current[selected].properties = data

        setLayers([...layers])

    },[ source, context, feed, rank, hashtag, variables, name, coordinates, twitchFallback ])

    if( source == null || context == null || feed == null || rank == null || 
        hashtag == null || variables == null || name == null || twitchFallback == null )
    {
        return (
            <></>
        )
    }
 

    return (
        <>
            <SettingsGroup name="General">
                <Control name="Name"
                         ctrl={ <Form.Control value={name} onChange={ e => setName(e.target.value)} size="sm"/>
                         }
                />
            </SettingsGroup>
            <SettingsGroup name="Data source">
                <Control name="Source"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={source} 
                             onChange={ e => setSource( e.target.value )}
                        >
                            <option value="streamer">Streamer</option>
                            <option value="chatter_1">Big chatter (1 min)</option>
                            <option value="chatter_5">Big chatter (5 min)</option>
                            <option value="hashtag">Hashtag</option>
                            <option value="last_chat">Last chat</option>
                            <option value="variable">Variable</option>

                        </Form.Select>
                     }
                />
                { ( source && source == "variable" ) &&
                <Control name="Context"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={context} 
                             onChange={ e => setContext( e.target.value )}
                        > 
                            <option value="username">Username</option>
                        </Form.Select>
                     }
                />
                } 
                { ( source != "variable" ) &&
                <Control name="Rank"
                     ctrl={
                        <Form.Select style={{width:'100%'}} size="sm" 
                                     value={rank} 
                                     onChange={ e => setRank( parseInt(e.target.value) )}
                        >
                            <option value={0}>1</option>
                            <option value={1}>2</option>
                            <option value={2}>3</option>
                            <option value={3}>4</option>
                            <option value={4}>5</option>
                            <option value={5}>6</option>
                            <option value={6}>7</option>
                            <option value={7}>8</option>
                            <option value={8}>9</option>
                            <option value={9}>10</option>
                        </Form.Select>
                     }
                />
                }

                { ( source == "hashtag" ) &&
                <Control name="Hashtag"
                     ctrl={
                        <Form.Control value={hashtag} 
                                      onChange={e => setHashtag(e.target.value)}
                                      style={{ width:'100%'}} 
                                      size="sm" 
                         />
                     }
                />
                }

                <Control name="Twitch fallback"
                     ctrl={
                        <Form.Check checked={twitchFallback} 
                                  onChange={e => setTwitchFallback(e.target.checked)} 
                                  size="sm"
                                  label="Load twitch avatar"
                        />
                     }
            />

            </SettingsGroup>
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={avatarVariables} 
                            reset={reset}
            />
            <CoordinateModule coordinates={coordinates}
                              setCoordinates={setCoordinates}
            />

        </>
    )
}
