import { useEffect, useRef, useState } from "react"
import { backendAccessGet, backendAccessPost, backendMe } from "../../Utils/Authentication"
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'

import Button from '@mui/material/Button'
import ReactMarkdown from 'react-markdown'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DarkButton } from '../SpiceUI/MUI'

import Checkmark from "../SpiceUI/Checkmark"
import { TiPlus } from "react-icons/ti";

import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoIosRemoveCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { RxCross1 } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { FaWindows } from "react-icons/fa"
import { MdDownload } from "react-icons/md";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { IoInformation } from "react-icons/io5";
import { FaExclamation } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import { LuMinus } from "react-icons/lu";
import { FaBookOpen } from "react-icons/fa";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from '@mui/material/Tooltip';
import { ControlButton, WhiteButton } from "../SpiceUI/MUI"

import ModuleSelector from "../Misc/ModuleSelector" 
import ActionBox from '../SpiceUI/ActionBox'
import Collapse from 'react-bootstrap/Collapse';

import AiModulesCfg from "../AiModules/AiModulesCfg"

export function DocumentationModal(props)
{
    const showModal = props.showModal 
    const setShowModal = props.setShowModal 
    const docRef = props.docRef

    return (
        <Modal show={showModal} onHide={ e => setShowModal(false)} size="lg">
            <Modal.Body style={{ fontSize:'10px', height:'500px', overflowY:'scroll'}}>
                <ReactMarkdown>
                {docRef.current}
                </ReactMarkdown>
            </Modal.Body>
        </Modal>
    )
}

export function AiUserConfigModal(props)
{
    const showModal = props.showModal 
    const setShowModal = props.setShowModal 
    const configTypeRef = props.configTypeRef

    const [ modelCfg, setModelCfg ] = useState(null) 

    const [ reset, setReset ] = useState(true)

    useEffect( () => {
        if(showModal)
        {
            let data = {}
            data.model_tag = configTypeRef.current.model_tag 
            data.reference = configTypeRef.current.reference 

            backendAccessPost('/api/models/get-model-cfg/', data).then( res => {
                if( res == null )
                {
                    return
                }

                setModelCfg(res.model_cfg)
                setReset( !reset )
            })
        }
    },[showModal])

    const save = () => {
        let data = {}
        data.model_tag = configTypeRef.current.model_tag 
        data.reference = configTypeRef.current.reference 
        data.model_cfg = modelCfg 

        backendAccessPost('/api/models/set-model-cfg/', data).then( res => {
            setShowModal(false)
        })
    }

    return (
        <Modal show={showModal} onHide={ e => setShowModal(false)} size="lg">
            <Modal.Body style={{ height:'500px'}}>
                { ( modelCfg && showModal ) &&
                <AiModulesCfg configType={configTypeRef} modelCfg={modelCfg} setModelCfg={setModelCfg} reset={reset} />
                }
            </Modal.Body>
            <Modal.Footer>
                <DarkButton size="small" style={{ marginRight:'5px', textTransform:'none'}}
                            onClick={ e => setShowModal(false)}>
                    Discard
                </DarkButton>
                <DarkButton size="small" style={{ textTransform:'none'}} onClick={save}>Save</DarkButton>
            </Modal.Footer>

        </Modal>
    )
}


export function SelectedWidgetItem(props) {
    const item = props.item
    const editWidget = props.editWidget
    const setSelected = props.setSelected
    const snapshots = props.snapshots

    const [ isHover, setIsHover ] = useState( false )
    const [ editHover, setEditHover ] = useState( false )
    const [ removeHover, setRemoveHover ] = useState( false )

    const deselect = () => {
        let d = {}
        d.widget_uid = item.widget_id 
        d.selected = 0 

        backendAccessPost('/api/widgets/update-selected/', d ).then( res => {
            if( res != null )
            {
                setSelected( res.selected )
            }
        })
    }

    return (
        <div style={{ width: '100%', height:'80px', marginBottom:'0px', padding:'5px' }}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
        >
            <div className="square border-1" 
                 style={{ width: '100px', 
                          height: '70px', 
                          border: '0.2px solid #F5F5F5', 
                          borderRadius: '2px', float:'left',
                        }}
            >
                <img style={{
                    margin: 'auto',
                    textAlign: 'center',
                    width:'90%' }} 
                    src={`data:${snapshots[item.widget_id].file_type};base64, ` + snapshots[item.widget_id].buffer} 
                />
            </div>
            <div style={{ width: 'calc( 100% - 100px )',textAlign:'left',paddingLeft:'10px', paddingTop:'5px', 
                          height:80, float:'right'}}>
                <div style={{ fontSize:10 }}><b>{item.widget_name}</b> </div>
                <div style={{ fontSize:10 }}>By <b>{item.owner}</b> </div>
                <div className="d-flex justify-content-end" style={{ marginTop:'5px', marginTop:'15px'}}>
                        <Button style={{ textTransform:'none', fontSize: 12, width:'50%', boxShadow: 'none',
                                         marginRight:'5px', height:'20px', borderRadius:'2px'}} 
                                size="small"
                                onClick={ e => editWidget(item.widget_id) }
                                variant="contained"
                        >
                            Edit
                        </Button>
                        <DarkButton style={{ textTransform:'none', fontSize: 12, width:'50%', boxShadow: 'none',
                                             height:'20px', borderRadius:'2px'}} 
                                    size="small"
                                    onClick={e => deselect(item.widget_id)}
                        >
                            Remove
                        </DarkButton>                    
                </div>
            </div>
        </div>
    )
}

export function SelectedAiModelItem(props) {
    const item = props.item
    const snapshots = props.snapshots
    const setUserAiModels = props.setUserAiModels

    const [ isHover, setIsHover ] = useState( false )
    const [ editHover, setEditHover ] = useState( false )
    const [ removeHover, setRemoveHover ] = useState( false )

    const deselect = () => {
        let d = {}
        d.model_tag = item.model_tag 
        d.reference = item.reference
        d.selected = 0 

        backendAccessPost('/api/models/update-selected/', d ).then( res => {
            if( res != null )
            {
                setUserAiModels( res.user_ai_models )
            }
        })
    }

    return (
        <div style={{ width: '100%', height:'80px', marginBottom:'0px', padding:'5px' }}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
        >
            <div className="square border-1" 
                 style={{ width: '100px', 
                          height: '70px', 
                          border: '0.2px solid #F5F5F5', 
                          borderRadius: '2px', float:'left',
                        }}
            >
                { snapshots[item.model_tag] &&
                <img style={{
                    margin: 'auto',
                    textAlign: 'center',
                    width:'90%' }} 
                    src={`data:${snapshots[item.model_tag].file_type};base64, ` + snapshots[item.model_tag].buffer} 
                />
                }
            </div>
            <div style={{ width: 'calc( 100% - 100px )',textAlign:'left',paddingLeft:'10px', paddingTop:'5px', 
                          height:80, float:'right'}}>
                <div style={{ fontSize:10 }}><b>{item.name}</b> </div>
                <div className="d-flex justify-content-end" style={{ marginTop:'5px', marginTop:'15px'}}>
                        <DarkButton style={{ textTransform:'none', fontSize: 12, width:'50%', boxShadow: 'none',
                                             height:'20px', borderRadius:'2px'}} 
                                    size="small"
                                    onClick={deselect}
                        >
                            Remove
                        </DarkButton>                    
                </div>
            </div>
        </div>
    )
}

export function FavoriteHeart( props )
{
    const favorite = props.favorite 
    const color = props.color 

    if( favorite == 0 )
    {
        return <FaRegHeart size={14} style={{ cursor:'pointer', color:color }} />
    }
    else 
    {
        return <FaHeart size={14} style={{ cursor:'pointer', color:color }} />
    }
}

export function WidgetBoxControl( props ) {
    const data = props.data
    const favorite = props.favorite 
    const toggleFavorite = props.toggleFavorite
    const edit = props.edit 
    const remove = props.remove
    const hasRemove = props.hasRemove
    
    return (
        <ButtonGroup style={{ height:'20px', maxWidth:'60px' }} 
                     size="small" variant="outline">
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={toggleFavorite}
            >
                <FavoriteHeart favorite={favorite} />
            </ControlButton>
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={edit}
                >
                <MdEdit  />
            </ControlButton>
            { hasRemove &&
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={remove}
            >
                <IoClose />
            </ControlButton>
            }
        </ButtonGroup>
    )
}

export function AiBoxControl( props ) {
    const openDocumentation = props.openDocumentation 
    const editAiModel = props.editAiModel
        
    return (
        <ButtonGroup style={{ height:'20px', maxWidth:'60px' }} 
                     size="small" variant="outline">
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={openDocumentation}
            >
                <FaBookOpen/>
            </ControlButton>
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={editAiModel}
                >
                <MdEdit  />
            </ControlButton>
        </ButtonGroup>
    )
}

export function WidgetBox( props ) {
    const data = props.data 
    const snapshots = props.snapshots 
    const updateWidgets = props.updateWidgets
    const setSelected = props.setSelected
    const editWidget = props.editWidget
    const removeWidget = props.removeWidget

    const [ favorite, setFavorite ] = useState(data.favorite)

    const [ isHover, setIsHover ] = useState(false)
    const [ isSelectHover, setIsSelectHover ] = useState(false)

    const toggleFavorite = () => {
        let new_favorite = 1-favorite

        let d = {}
        d.widget_uid = data.widget_id 
        d.favorite = new_favorite 

        backendAccessPost('/api/widgets/update-favorite/', d ).then( res => {
            if( res != null )
            {
                setFavorite( new_favorite )
                updateWidgets( res.user_widgets, res.spicy_widgets, res.shared_widgets, res.favorites )
            }
        })
    }

    const selectWidget = () => {
        let d = {}
        d.widget_uid = data.widget_id 
        d.selected = 1

        backendAccessPost('/api/widgets/update-selected/', d ).then( res => {
            if( res != null )
            {
                setSelected( res.selected )
            }
        })
    }

    useEffect( () => {
        setFavorite( data.favorite )
    },[data])
    
    return (
        <div style={{ width:'220px', height:'167px', marginRight:'10px', marginBottom:'10px'}}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)} >
            <div style={{ height: '167px', width: '100%',
                          border: (isHover) ? '0.2px solid #DADADA':'',
                          boxShadow:(isHover) ? '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)': ''
                        }}>
                <div style={{ width:'100%', height:'123px',
                              border: '0.2px solid #DADADA',
                              position: 'relative', alignItems:'center'
                            }}> 
                    <div className="d-flex justify-content-center align-items-center"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px'}}>
                        <img style={{ maxWidth: '90%', maxHeight:'90%' }} 
                             src={`data:${snapshots[data.widget_id].file_type};base64, ` + snapshots[data.widget_id].buffer}
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-start"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px', zIndex:1, padding:'10px'}}>
                        { isHover &&
                        <WidgetBoxControl favorite={favorite} toggleFavorite={toggleFavorite}
                                          edit={ e => editWidget(data.widget_id)}
                                          remove={ e => removeWidget(data)}
                                          hasRemove = { removeWidget != null }
                        />
                    }
                    </div>
                </div>
                <div style={{ width:'100%', height:'calc(100% - 123px)', padding:'10px' }}>
                    <div className="d-flex justify-content-center align-items-start"
                         style={{ height:'100%', width:'27px', float:'left'}}>
                        
                        <WhiteButton style={{ maxWidth: '22px', minWidth:'22px', maxHeight: '22px', minHeight:'22px', 
                                              borderRadius:'0px', padding:'2px', 
                                              border: '0.5px solid #DADADA' }}
                                     onClick={selectWidget}
                        >
                            <TiPlus size={16}  />
                        </WhiteButton>
                        <div style={{ float: 'left', marginLeft: '5px', textAlign:'left' }}>
                        </div>
                    </div>
                    <div style={{ height:'100%', width:'calc(100% - 27px)', float:'right', 
                                  fontSize:'8px', textAlign:'left' }}>
                        <div style={{ width:'100%', fontWeight:500}}>
                            {data.widget_name}
                        </div>
                        <div style={{ width:'100%', overflow:'hidden' }}>
                            <span style={{ fontWeight: 200 }}>By </span>
                            <span style={{ fontWeight: 500, paddingLeft: 2, wordWrap:"break-word"}}>{data.owner_spice_name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function AiModelBox( props ) {
    const data = props.data 
    const snapshots = props.snapshots 
    const updateWidgets = props.updateWidgets
    const setUserAiModels = props.setUserAiModels
    const editWidget = props.editWidget
    const removeWidget = props.removeWidget

    const showDocModal = props.showDocModal 
    const setShowDocModal = props.setShowDocModal 
    const docRef = props.docRef

    const showAiCfgModal = props.showAiCfgModal
    const setShowAiCfgModal = props.setShowAiCfgModal 
    const cfgRef = props.cfgRef

    const [ isHover, setIsHover ] = useState(false)
    const [ isSelectHover, setIsSelectHover ] = useState(false)

    const selectModel = () => {
        let d = {}
        d.model_tag = data.model_tag
        d.reference = data.reference
        d.name = data.name
        d.selected = 1

        backendAccessPost('/api/models/update-selected/', d ).then( res => {
            if( res != null )
            {
                setUserAiModels( res.user_ai_models )
            }
        })
    }

    const openDocumentation = () => {
        docRef.current = atob(data.documentation.buffer)
        setShowDocModal(true)
    }

    const editAiModel = () => {
        cfgRef.current = data
        setShowAiCfgModal(true)
    }

    return (
        <div style={{ width:'220px', height:'167px', marginRight:'10px', marginBottom:'10px'}}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)} >
            <div style={{ height: '167px', width: '100%',
                          border: (isHover) ? '0.2px solid #DADADA':'',
                          boxShadow:(isHover) ? '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)': ''
                        }}>
                <div style={{ width:'100%', height:'123px',
                              border: '0.2px solid #DADADA',
                              position: 'relative', alignItems:'center'
                            }}> 
                    <div className="d-flex justify-content-center align-items-center"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px'}}>
                        <img style={{ maxWidth: '90%', maxHeight:'90%' }} 
                             src={`data:${snapshots[data.model_tag].file_type};base64, ` + snapshots[data.model_tag].buffer}
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-start"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px', zIndex:1, padding:'10px'}}>
                        { isHover &&
                        <AiBoxControl openDocumentation={openDocumentation}
                                      editAiModel={editAiModel}
                        />
                        }
                    </div>

                </div>
                <div style={{ width:'100%', height:'calc(100% - 123px)', padding:'10px' }}>
                    <div className="d-flex justify-content-center align-items-start"
                         style={{ height:'100%', width:'27px', float:'left'}}>
                        
                        <WhiteButton style={{ maxWidth: '22px', minWidth:'22px', maxHeight: '22px', minHeight:'22px', 
                                              borderRadius:'0px', padding:'2px', 
                                              border: '0.5px solid #DADADA' }}
                                     onClick={selectModel}
                        >
                            <TiPlus size={16}  />
                        </WhiteButton>
                        <div style={{ float: 'left', marginLeft: '5px', textAlign:'left' }}>
                        </div>
                    </div>
                    <div style={{ height:'100%', width:'calc(100% - 27px)', float:'right', 
                                  fontSize:'8px', textAlign:'left' }}>
                        <div style={{ width:'100%', fontWeight:500}}>
                            {data.name}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function WidgetsRow( props ) {
    const rowName = props.rowName 
    const widgets = props.widgets 
    const snapshots = props.snapshots
    const updateWidgets = props.updateWidgets
    const setSelected = props.setSelected
    const editWidget = props.editWidget
    const removeWidget = props.removeWidget
    const setState = props.setState
    const browserWidth = props.browserWidth

    const [ expanded, setExpanded ] = useState(false)
    const [ numItems, setNumItems ] = useState(0)

    const [ subWidgets, setSubWidgets ] = useState( widgets )

    useEffect( () => {
    },[])

    useEffect( () => {
        if( expanded )
        {
            let ww = structuredClone(widgets)
            setSubWidgets(ww)
        }
        else 
        {
            let collapsed_size = browserWidth / 230
            let ww = structuredClone(widgets.slice(0,Math.floor(collapsed_size)))
            setSubWidgets(ww)
        }
    }, [expanded, widgets, browserWidth] )

    return (
        <div style={{ width:'100%', marginBottom:'15px'}}>
            <div className="d-flex justify-content-left align-items-center" 
                 style={{ width:'100%', height:'20px', paddingLeft:'15px', fontSize:14 }}>
                <div style={{ float:'left', cursor:'pointer'}}>
                    <DarkButton onClick={ e => setExpanded(!expanded)} style={{ fontSize:12, textTransform:'none' }} size="small">
                        { (expanded) ? <><LuMinus /> Hide</> : <><LuPlus /> All</> }
                    </DarkButton>
                </div>

                <div style={{ float:'left', marginLeft:'10px'}}>
                {rowName} widgets  
                </div>
            </div>

            <div style={{ width:'100%', padding:'15px' }}>

                { subWidgets.map( (widget,idx) => 
                <div style={{ float:'left'}} key={idx}>
                <WidgetBox data={widget} 
                           snapshots={snapshots} 
                           updateWidgets={updateWidgets}
                           setSelected={setSelected} 
                           editWidget={editWidget}
                           removeWidget={removeWidget} 
                           key={idx} 
                />
                </div>
                )}
            </div> 
        </div>
    )
}

export function AiModelsRow( props )
{
    const rowName = props.rowName 
    const models = props.models
    const snapshots = props.snapshots
    const browserWidth = props.browserWidth
    const setUserAiModels = props.setUserAiModels

    const [ expanded, setExpanded ] = useState(false)
    const [ numItems, setNumItems ] = useState(0)

    const [ subModels, setSubModels ] = useState( models )

    const [ showDocModal, setShowDocModal ] = useState(false)
    const docRef = useRef("")
    const [ showAiCfgModal, setShowAiCfgModal ] = useState(false)
    const cfgRef = useRef("")

    useEffect( () => {
        if( expanded )
        {
            let mm = structuredClone(models)
            setSubModels(mm)
        }
        else 
        {
            let collapsed_size = browserWidth / 230
            let mm = structuredClone(models.slice(0,Math.floor(collapsed_size)))
            setSubModels(mm)
        }
    }, [expanded, models, browserWidth] )

    return (
        <>
        <div style={{ width:'100%', marginBottom:'15px'}}>
            <div className="d-flex justify-content-left align-items-center" 
                 style={{ width:'100%', height:'20px', paddingLeft:'15px', fontSize:14 }}>
                <div style={{ float:'left', cursor:'pointer'}}>
                    <DarkButton onClick={ e => setExpanded(!expanded)} style={{ fontSize:12, textTransform:'none' }} size="small">
                        { (expanded) ? <><LuMinus /> Hide</> : <><LuPlus /> All</> }
                    </DarkButton>
                </div>

                <div style={{ float:'left', marginLeft:'10px'}}>
                {rowName}  
                </div>
            </div>

            <div style={{ width:'100%', padding:'15px' }}>

                { subModels.map( (model,idx) => 
                <div style={{ float:'left'}} key={idx}>
                <AiModelBox data={model} 
                            snapshots={snapshots}
                            setUserAiModels={setUserAiModels}
                            key={idx} 
                            showDocModal={showDocModal}
                            setShowDocModal={setShowDocModal}
                            docRef={docRef}
                            showAiCfgModal={showAiCfgModal}
                            setShowAiCfgModal={setShowAiCfgModal}
                            cfgRef={cfgRef}
                />
                </div>
                )}
            </div> 
        </div>
        <DocumentationModal showModal={showDocModal}
                            setShowModal={setShowDocModal}
                            docRef={docRef}
        /> 
        <AiUserConfigModal showModal={showAiCfgModal}
                           setShowModal={setShowAiCfgModal}
                           configTypeRef={cfgRef}
        /> 

        </>
    )

}

export function SelectedWidgetsBar( props ) {

    const selected = props.selected 
    const setSelected = props.setSelected
    const userAiModels = props.userAiModels 
    const setUserAiModels = props.setUserAiModels
    const snapshots = props.snapshots 
    const editWidget = props.editWidget
    const maxWidgets = props.maxWidgets
    const navigate = props.navigate


    return (
        <div style={{ width:'100%', height:'100%', border: '0.2px solid #DADADA', borderRadius: '2px', padding:'10px'}}>
            <div className="d-flex align-items-center justify-content-center" style={{ height: '30px', width: '100%', marginBottom:'5px' }}>        
                <div style={{ width:'80%' }}>
                <div className="fw-light" style={{ float:'left', fontSize:13 }}>
                    Selected widgets ({selected.length}/{maxWidgets})
                </div>
                <Tooltip title="Selected widgets are synced to the Spice.Stream application. Upgrade your account to 
                                            increase the number of selected widgets."
                         arrow
                >
                    <div style={{ float:'right', marginLeft:'5px', top:-10 }}>
                        <FaExclamation />
                    </div>

                </Tooltip>
                </div>
            </div>
            <div style={{ height:'calc(100% - 110px)', overflowY:'scroll' }}>
                { selected &&
                <ModuleSelector check={selected.length > 0 || userAiModels.length > 0}
                                childTrue={
                                    <>
                                        { selected.map( (item,idx) => 
                                        <SelectedWidgetItem item={item} snapshots={snapshots} setSelected={setSelected} 
                                                            editWidget={editWidget} key={idx}
                                        />
                                        )}

                                        { userAiModels.length > 0 &&
                                        <>
                                        <div className="fw-light" style={{ fontSize:13 }}>
                                            AI Models
                                        </div>
                                        { userAiModels.map( (item,idx) => 
                                        <SelectedAiModelItem item={item} snapshots={snapshots} 
                                                             setUserAiModels={setUserAiModels} key={idx}
                                        />
                                        )}
                                        </>
                                        }
                                    </>
                                }
                                childFalse={
                                    <div className="d-flex justify-content-center align-items-center" 
                                         style={{ height:'100%', width:'100%', padding:'5px', fontSize:13 }}>
                                        <span>
                                            Add (<TiPlus />) widgets to this list. Selected widgets will be synced to  
                                            the <b style={{ cursor:'pointer'}} 
                                                   onClick={ e => navigate("/downloads")}>Spice.Stream app</b>.
                                        </span>
                                    </div>
                                }
                />
                }
             </div>
             <div style={{ height:'80px', width:'100%' }}>
                <div className="fw-light" style={{ marginBottom:'5px', fontSize:13 }}>
                    Spice.Stream App
                </div>
                <DarkButton style={{ width:'80%', textTransform:'none' }} 
                            onClick={ e => navigate("/downloads")}>
                    Download<IoCloudDownloadSharp style={{ marginLeft:'5px'}} />
                </DarkButton>
                {/*<ActionBox title="Spice.Stream App" 
                           onClick={e => setState("6")}
                           showTitle={true}
                >
                    Download <IoCloudDownloadSharp style={{ color:'#000000', cursor:'pointer'}} />
                </ActionBox>*/}

             </div>


        </div>
    )
}

export default function WidgetBrowser(props) {
    const editWidget = props.editWidget
    const userConfig = props.userConfig
    const navigate = props.navigate

    const [userWidgets, setUserWidgets] = useState([])
    const [spicyWidgets, setSpicyWidgets] = useState([])
    const [sharedWidgets, setSharedWidgets] = useState({})
    const [aiModels, setAiModels ] = useState([])
    const [snapshots, setSnapshots] = useState(null)

    const [ selected, setSelected ] = useState([])
    const [ userAiModels, setUserAiModels ] = useState([])
    const [ favorites, setFavorites ] = useState([])

    const [ showFavorites, setShowFavorites ] = useState( false )

    const [ maxWidgets, setMaxWidgets ] = useState( userConfig.config.max_widgets )

    const initialized = useRef(false)
    const browserRef = useRef(null)

    const [ browserWidth, setBrowserWidth ] = useState(null)

    const reload = () => {
        if( initialized.current )
        {
            return 
        }

        initialized.current = true

        backendAccessGet('/api/widgets/user-widgets/').then(res => {
            if (!res) {
                return
            }

            setSnapshots(res.snapshots)

            if( res.selected )
            {
                setSelected(res.selected)
            }

            if( res.ai_models )
            {
                setAiModels(res.ai_models)
            }

            if( res.user_ai_models )
            {
                setUserAiModels(res.user_ai_models)
            }

            updateWidgets( res.user_widgets, res.spicy_widgets, res.shared_widgets, res.favorites )
        })
    }

    const verifyWidth = () => {
        setBrowserWidth( browserRef.current.clientWidth )
    }

    useEffect(() => {
        reload()

        setBrowserWidth( browserRef.current.clientWidth )
        window.addEventListener('resize', verifyWidth);
        return () => window.removeEventListener('resize', verifyWidth);
    }, [])

    const updateWidgets = ( user_widgets, spicy_widgets, shared_widgets, favorites ) => {
        if( user_widgets )
        {
            setUserWidgets( user_widgets )
        }

        if( spicy_widgets )
        {
            setSpicyWidgets( spicy_widgets )
        }

        if( shared_widgets )
        {
            setSharedWidgets( shared_widgets )
        }

        if( favorites )
        {
            setFavorites( favorites )
        }
    }

    const removeWidget = (widget_data) => {
        if( !window.confirm(`Are you sure you want to remove widget ${widget_data.widget_name}?`) )
        {
            return
        }


        const widget_id = widget_data.widget_id
        let data = {}
        data['widget_uid'] = widget_id

        backendAccessPost('/api/widgets/remove/', data).then(res => {
            if (res == null) {
                return
            }

            setSelected(res.selected)
            updateWidgets( res.user_widgets, res.spicy_widgets, res.shared_widgets, res.favorites )
        })
    }

    const updateSelected = (uid, selected) => {
        let data = {}
        data['widget_uid'] = uid
        data['selected'] = selected

        backendAccessPost('/api/widgets/update-selected/', data).then(res => {
            if (res == null) {
                return
            }

            setUserWidgets(res.widgets)
        })
    }

    useEffect( () => {

        if( favorites.length > 0 )
        {
            setShowFavorites( true )
        }
        else 
        {
            setShowFavorites( false )
        }
    }, [ favorites ] )

    useEffect( () => {
        setMaxWidgets(userConfig.config.max_widgets)
    }, [userConfig] )

    return (
        <Container style={{ maxWidth:'1400px', height:'100%', paddingLeft:'50px', paddingRight:'50px', paddingTop:'20px'}}>
            <div className="d-flex justify-content-start"
                 style={{ height: '100%', width: '300px', float:'left' }}>
                <div style={{width:'300px', height:'calc(100% - 25px)', marginTop:'0px' }}>
                    <SelectedWidgetsBar selected={selected} 
                                        setSelected={setSelected}
                                        userAiModels={userAiModels}
                                        setUserAiModels={setUserAiModels}
                                        snapshots={snapshots} 
                                        maxWidgets={maxWidgets}
                                        editWidget={editWidget}
                                        navigate={navigate}
                    />
                </div>
            </div>
            <div style={{ height: '100%', width: 'calc(100% - 300px)', overflowY:'scroll', float:'right', paddingTop:'15px' }}
                 ref={browserRef}>
                { showFavorites && 
                <WidgetsRow rowName="Favorit" widgets={favorites} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected}
                            editWidget={editWidget}
                            browserWidth={browserWidth}
                />
                }
                { userWidgets.length > 0 &&
                <WidgetsRow rowName="My" widgets={userWidgets} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected}
                            editWidget={editWidget}
                            removeWidget={removeWidget}
                            browserWidth={browserWidth}
                />
                }
                { snapshots &&
                <AiModelsRow rowName="AI Models" 
                             models={aiModels} 
                             snapshots={snapshots} 
                             browserWidth={browserWidth}
                             setUserAiModels={setUserAiModels}
                />
                }
                { spicyWidgets.length > 0 &&
                <WidgetsRow rowName="Spicy" widgets={spicyWidgets} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected}
                            editWidget={editWidget}
                            browserWidth={browserWidth}
                />
                }
                { Object.entries(sharedWidgets).map( (item,idx) => 
                <WidgetsRow rowName={item[0] + "'s"} widgets={item[1]} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected} 
                            editWidget={editWidget}
                            removeWidget={removeWidget}
                            browserWidth={browserWidth}
                />
                )}
            </div>
        </Container>
    )
}


