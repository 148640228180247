import { useEffect, useState, useRef } from "react";
import DNDList from '../Misc/DNDList'

import { IoMdClose } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { FaPlusSquare, FaRegWindowClose } from "react-icons/fa";
import { SlArrowDown } from "react-icons/sl";
import { MdDeselect } from "react-icons/md";
import { HiOutlineDuplicate } from "react-icons/hi";

import Avatar from '@mui/material/Avatar'
import { blue } from '@mui/material/colors'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import { stringToHash } from '../Misc/FileTools';
import UploadModal from './UploadModal'
import AddLayerModal from './AddLayerModal'

export function LayerImage( props )
{
    const item = props.item

    if( item.type == "image" )
    {
        return (
            <img src={`data:${item.filetype}, ${item.patch}`} 
                 style={{ maxHeight: '100%', maxWidth: '100%' }} 
            />
        )
    }
    else if( item.type == "avatar" )
    {
        return (
            <Avatar style={{ maxHeight: '100%', 
                             maxWidth: '100%', 
                             backgroundColor:'blue' }}
            >
                A
            </Avatar>
        )
    }
    else if( item.type == "emoticon" )
    {
        return (
        <Avatar style={{ maxHeight: '100%', 
                         maxWidth: '100%', 
                         backgroundColor:'red' }}
        >
            E
        </Avatar>
        )
    }
    else if( item.type == "text" )
    {
        return (
        <Avatar style={{ maxHeight: '100%', 
                         maxWidth: '100%', 
                         backgroundColor:'green' }}
        >
            T
        </Avatar>
        )
    }
    else if( item.type == "dyno" )
    {
        return (
        <Avatar style={{ maxHeight: '100%', 
                         maxWidth: '100%', 
                         backgroundColor:'orange' }}
        >
            D
        </Avatar>
        )
    }


    return ( 
        <></>
    )
}

export function LayerItem(props) {
    const item = props.item
    const index = props.index
    const onClick = props.params.onClick
    const selected = props.params.selected
    const remove = props.params.remove
    const duplicate = props.params.duplicate

    return (
        <div style={{ height: '60px', 
                      width: '135px', marginBottom:'5px', 
                      border:'0.2px dashed #AEAEAE', 
                      borderRadius:'5px',
                      backgroundColor: (item.id == selected) ? '#D6EEFF' : '#FFFFFF', 
                      position:'relative',
                      cursor:'grab'
                    }}
            onClick={e => onClick(index,item)}
        >
            <div className="d-flex align-items-center justify-content-center"
                style={{ width: '100%', 
                         height: '100%', 
                         backgroundColor: (item.id == selected) ? '#D6EEFF' : '#FFFFFF', 
                         borderTopLeftRadius: '5px', 
                         borderBottomLeftRadius: '5px', 
                         padding: '5px',
                         top:'0px', left:'0px', position:'absolute'}}
            >
                <LayerImage item={item}/>
            </div>
            <div style={{ position:'absolute',
                          top:'-5px',
                          right:'2px',
                          zIndex:1,
                          cursor:'pointer'
                        }}
            >
                <IoMdClose onClick={e => remove(index)}/>
            </div>
            <div style={{ position:'absolute',
                          bottom:'0px',
                          right:'2px',
                          zIndex:1,
                          cursor:'pointer'
                        }}
            >
                <HiOutlineDuplicate onClick={ e => duplicate(index, item ) }/>
            </div>
        </div>
    )
}

export default function Layers(props) {
    const layers = props.layers
    const setLayers = props.setLayers
    const layersCfg = props.layersCfg

    const selected = props.selected
    const setSelected = props.setSelected
    const selectedRef = props.selectedRef 

    const selectedIdx = props.selectedIdx
    const setSelectedIdx = props.setSelectedIdx

    const pickerImages = props.pickerImages

    const accountType = props.accountType

    const [showUploadModal, setShowUploadModal] = useState(false)
    const scrollContainerRef = useRef(null);

    const addLayer = () => {
        setShowUploadModal(true)
    }

    const closeModal = () => {
        setShowUploadModal(false)
    }

    const addCloseModal = (type, data) => {

        let content = null
        let hash = null 
        let filename = null
        let filetype = null
        let duration = null
    
        if( type == 'image' )
        {
            content = data.content
            hash = data.hash
            filename = data.filename
            filetype = data.filetype
            duration = data.duration
        }

        let l = structuredClone(layers)
        let uid = `L-${Date.now()}`

        let push_data = { id: uid, type: type, patch: content, 
                          filename: filename, filetype: filetype, hash: hash, duration: duration }

        l.unshift( push_data )
        layersCfg.current[uid] = { effects: {}, 
                                   properties:{}, 
                                   type:type, 
                                   fixed_aspect_ratio: !(type == 'text' || type == 'dyno') 
                                }
        
        setLayers(l)

        setShowUploadModal(false)
        setSelected(uid)
        setSelectedIdx(l.length - 1)
    }

    const reorder = (list, startIndex, endIndex) => {
        var l = structuredClone(layers)

        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setLayers(l)
    }

    const remove = (index) => {
        var l = structuredClone(layers)

        let uid = l[index].id
        const [r] = l.splice(index, 1);

        delete layersCfg.current[uid]

        setLayers(l)
        setSelected(null)
        setSelectedIdx(-1)
    }

    const duplicate = ( index, item ) => {
        let old_uid = item.id 
        let new_item = structuredClone(item)
        let new_cfg = structuredClone(layersCfg.current[old_uid])

        if( new_cfg.location )
        {
            new_cfg.location.x += 10
            new_cfg.location.y += 10
        }

        let l = structuredClone(layers)
        let uid = `L-${Date.now()}`

        new_item.id = uid 
        l.splice( index,0,new_item)

        //l.unshift( new_item )
        layersCfg.current[uid] = new_cfg
        setLayers(l)

        setShowUploadModal(false)
        setSelected(uid)
        setSelectedIdx(index)
    }

    const onClick = (index, item) => {
        setSelected(item.id)
        setSelectedIdx(index)
    }

    const deselect = () => {
        setSelected(null)
        setSelectedIdx(-1)
    }

    const scrollDown = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    }

    return (
        <>
            <div style={{ width: '100%', height: '40px' }}>
                <div style={{ width: '100%', height: '20px' }}>
                    <ButtonGroup variant="text">
                        <Button onClick={addLayer} size="small">
                            <FaPlusSquare style={{ fontSize:'24px'}} /> 
                        </Button>
                        <Button onClick={deselect} size="small">
                            <MdDeselect style={{ fontSize:"24px"}} />
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                <div ref={scrollContainerRef} 
                     style={{ width: '100%', 
                              height: 'calc(100% - 50px)', 
                              overflowY: 'scroll',
                              padding: '5px'}}>
                    <DNDList items={layers}
                        reorder={reorder}
                        direction="vertical"
                        Inner={LayerItem}
                        innerParams={{ 'onClick': onClick, 
                                       selected: selected, 
                                       remove: remove,
                                       duplicate: duplicate }}
                    />
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
                    <SlArrowDown onClick={scrollDown} 
                                 style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
            <AddLayerModal showModal={showUploadModal}
                           closeModal={closeModal}
                           addCloseModal={addCloseModal}
                           accountType={accountType}
            />
        </>
    )
}
