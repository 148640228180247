import { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'

export function Item( props )
{
    const name = props.name 

    return (
        <div style={{ width:'100%', height:'30px', marginBottom:'5px'}}>
            <div className="d-flex justify-content-start align-items-center" 
                 style={{ width:'100px', height:'100%', float:'left' }}>
                <b>{name}</b>
            </div>
            <div style={{ width:'calc(100% - 100px)', height:'100%', float:'left'}}>
                { props.children }
            </div>
        </div>
    )
}

export function DynamicHide( props )
{
    const dynamicHide = props.dynamicHide 
    const setDynamicHide = props.setDynamicHide 
    
    const [ variable, setVariable ] = useState(null)
    const [ value, setValue ] = useState(null)

    useEffect( () => {
        setVariable( (dynamicHide.variable) ? dynamicHide.variable : "" )
        setValue( (dynamicHide.value) ? dynamicHide.value : "" )
    },[])

    useEffect( () => {
        if( variable == null || value == null )
        {
            return
        }

        let dh = structuredClone(dynamicHide)

        dh.variable = variable 
        dh.value = value 

        setDynamicHide(dh)

    },[ variable,value])

    return (
        <div style={{ width:'100%' }}>
            <div className="d-flex justify-content-end" style={{ width:'100%', marginBottom:'5px' }}>
                <b>Dynamic Hide</b>
            </div>
            <div>
                <Item name="Variable">
                    <Form.Control value={variable} onChange={ e => setVariable(e.target.value)} size="sm"/>
                </Item>
                <Item name="Value">
                    <Form.Control value={value} onChange={ e => setValue(e.target.value)} size="sm"/>
                </Item>

            </div>

        </div>
    )
}


export default function SettingsModal( props )
{
    const showModal = props.showModal 
    const closeModal = props.closeModal
    const saveCloseModal = props.saveCloseModal 
    const settings = props.settings

    const [ dynamicHide, setDynamicHide ] = useState(null)

    const reload = () => {
        setDynamicHide( (settings.dynamic_hide) ? settings.dynamic_hide : {} )

    }

    useEffect( () => {
        if( showModal )
        {
            reload()
        }
    },[showModal])

    const save = () => {
        let s = structuredClone(settings)
        s.dynamic_hide = dynamicHide 
        saveCloseModal(s)
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header>
                Widget Settings
            </Modal.Header>
            <Modal.Body style={{ fontSize:12 }}>
                { dynamicHide &&
                <DynamicHide dynamicHide={dynamicHide} setDynamicHide={setDynamicHide} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" 
                        size="sm" 
                        style={{ marginRight:'5px', textTransform:'none' }} 
                        onClick={closeModal}>
                    Close
                </Button>
                <Button variant="contained" 
                        size="sm" 
                        style={{ textTransform:'none' }}
                        onClick={save}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

