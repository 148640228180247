import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"
import CoordinateModule from "../Modules/CoordinateModule"

import RandomPickerProperties from "./DynoModules/RandomPickerProperties"
import TwitchAudiencePickerProperties from "./DynoModules/TwitchAudiencePickerProperties"
import LottaryProperties from "./DynoModules/LottaryProperties"
import ImagePickerProperties from "./DynoModules/ImagePickerProperties"
import PredictProperties from "./DynoModules/PredictProperties"
import PredictChatProperties from "./DynoModules/PredictChatProperties"

export function DynoPicker( props )
{
    const dynoMode = props.dynoMode 
    const dynoCfg = props.dynoCfg
    const setDynoCfg = props.setDynoCfg

    const selected = props.selected 
    const reset = props.reset 
    const modalOpen = props.modalOpen 

    if( dynoMode == 'random_picker' )
    {
        return (
            <RandomPickerProperties dynoCfg={dynoCfg} 
                                    setDynoCfg={setDynoCfg}
                                    modalOpen={modalOpen} 
                                    selected={selected}
                                    reset={reset}
            />
        )
    }

    if( dynoMode == 'twitch_audience_picker' )
    {
        return (
            <TwitchAudiencePickerProperties dynoCfg={dynoCfg} 
                                            setDynoCfg={setDynoCfg}
                                            selected={selected}
                                            reset={reset}
            />
        )
    }

    if( dynoMode == "lottary" )
    {
        return (
            <LottaryProperties dynoCfg={dynoCfg} 
                               setDynoCfg={setDynoCfg}
                               selected={selected}
                               reset={reset}
            />
        )
    }

    if( dynoMode == "image_picker" )
    {
        return (
            <ImagePickerProperties dynoCfg={dynoCfg}
                                   setDynoCfg={setDynoCfg}
                                   selected={selected}
                                   reset={reset}
                                   modalOpen={modalOpen}
            />
        )
    }

    if( dynoMode == "predict" )
    {
        return (
            <PredictProperties dynoCfg={dynoCfg}
                               setDynoCfg={setDynoCfg}
                               selected={selected}
                               reset={reset}
                               modalOpen={modalOpen}
            />
        )
    }

    if( dynoMode == "predict_chat" )
    {
        return (
            <PredictChatProperties dynoCfg={dynoCfg}
                            setDynoCfg={setDynoCfg}
                            selected={selected}
                            reset={reset}
                            modalOpen={modalOpen}
            />
        )
    }


    return (
        <></>
    )
}

export default function DynoProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType
    const reset = props.reset

    const [ name, setName ] = useState(null)
    const [ dynoMode, setDynoMode ] = useState(null)
    const [ dynoCfg, setDynoCfg ] = useState(null)

    const [ coordinates, setCoordinates ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        setDynoCfg( layersCfg.current[selected] )
        let n = layersCfg.current[selected].name 
        setName( (n) ? n : "" )

        let properties = layersCfg.current[selected].properties

        if( properties == null )
        {
            properties = {}
        }

        setDynoMode((properties.dyno_mode) ? properties.dyno_mode : 'random_picker')

        let c = {}
        c.location = layersCfg.current[selected].location
        c.fixed_aspect_ratio = layersCfg.current[selected].fixed_aspect_ratio

        setCoordinates( c )
    }

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        if( dynoMode == null )
        {
            return
        }

        let cfg = structuredClone(dynoCfg)
        cfg.properties.dyno_mode = dynoMode 

        if( dynoMode == "image_picker" )
        {
            layersCfg.current[selected].fixed_aspect_ratio = true 
        }
        else 
        {
            layersCfg.current[selected].fixed_aspect_ratio = false
        }

        setDynoCfg(cfg)

    },[ dynoMode ])

    useEffect( () => {
        if( dynoCfg == null || dynoMode == null || coordinates == null || name == null )
        {
            return
        }

        layersCfg.current[selected].properties = dynoCfg.properties 
        
        if( dynoMode == "image_picker" && dynoCfg.properties.image_picker_cfg )
        {
            if( dynoCfg.properties.image_picker_cfg.images )
            {
                if( dynoCfg.properties.image_picker_cfg.images.length > 0 )
                {
                    const image = dynoCfg.properties.image_picker_cfg.images[0]

                    let w = layersCfg.current[selected].location.width
                    layersCfg.current[selected].location.height = w / image.aspect_ratio
                }
            }
        }

        layersCfg.current[selected].name = name
        layersCfg.current[selected].location = coordinates.location
        layersCfg.current[selected].fixed_aspect_ratio = coordinates.fixed_aspect_ratio

        setLayers([...layers])

    },[ dynoCfg, coordinates, name ])

    if( dynoMode == null || dynoCfg == null || name == null )
    {
        return (<></>)
    }

    return (
        <>
            <SettingsGroup name="General">
                <Control name="Name"
                         ctrl={<Form.Control value={name}
                                             onChange={ e => setName(e.target.value) }
                                             size="sm"
                                />
                         }
                />
                <div style={{ marginBottom:'10px'}}>
                    <Control name="Dyno mode"
                             ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={dynoMode} 
                             onChange={ e => setDynoMode( e.target.value )}
                        >
                            <option value="random_picker">Random Picker</option>
                            <option value="twitch_audience_picker">Twitch Audience Picker</option>
                            <option value="lottary">Lottary</option>
                            <option value="image_picker">Image Picker</option>
                            <option value="predict">Predict</option>
                            <option value="predict_chat">Predict Chat</option>

                        </Form.Select>
                     }
                    />
                </div>
            </SettingsGroup>
            <DynoPicker dynoMode={dynoMode}
                        dynoCfg={dynoCfg}
                        setDynoCfg={setDynoCfg}
                        selected={selected}
                        reset={reset}
                        modalOpen={modalOpen}
            />
            <CoordinateModule coordinates={coordinates}
                              setCoordinates={setCoordinates}
            />
        </>
    )
}
