import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"

import ShakerEffect from '../Effects/ShakerEffect'
import ScaleEffect from "../Effects/ScaleEffect";
import RotationEffect from '../Effects/RotationEffect'

import CoordinateModule from "../Modules/CoordinateModule"

export default function ImageProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType

    const [ name, setName ] = useState(null)
    const [ coordinates, setCoordinates ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        let properties = layersCfg.current[selected].properties
        let n = layersCfg.current[selected].name 
        setName( (n) ? n : "" )

        let c = {}
        c.location = layersCfg.current[selected].location
        c.fixed_aspect_ratio = layersCfg.current[selected].fixed_aspect_ratio

        if( c.location && c.fixed_aspect_ratio )
        {
            setCoordinates(c)
        }


        if( properties == null )
        {
            properties = {}
        }
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        if( name == null || coordinates == null )
        {
            return
        }

        layersCfg.current[selected].name = name 

        
        layersCfg.current[selected].location = coordinates.location
        layersCfg.current[selected].fixed_aspect_ratio = coordinates.fixed_aspect_ratio

        setLayers([...layers])

        
    },[ name, coordinates ])

    return (
        <>
            <SettingsGroup name="General">
                <Control name="Name"
                         ctrl={<Form.Control value={name} onChange={ e => setName(e.target.value)} size="sm" />
                         }
                />
            </SettingsGroup>
            <SettingsGroup name="Visual effects">
                <ShakerEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                              selected={selected} selectedIdx={selectedIdx} />
                <RotationEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                                selected={selected} selectedIdx={selectedIdx} />
                <ScaleEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                             selected={selected} selectedIdx={selectedIdx} />

            </SettingsGroup>
            <CoordinateModule coordinates={coordinates} setCoordinates={setCoordinates}/>
        </>
    )
}
