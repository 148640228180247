import { useState, useEffect, useRef } from 'react'
import { SettingsGroup, Control } from './General'
import Form from 'react-bootstrap/Form'


export default function CoordinateModule( props )
{
    const coordinates = props.coordinates
    const setCoordinates = props.setCoordinates
    const selected = props.selected

    const [ x, setX ] = useState(null)
    const [ xError, setXError ] = useState(false)

    const [ y, setY ] = useState(null)
    const [ yError, setYError ] = useState(false)

    const [ width, setWidth ] = useState(null)
    const [ widthError, setWidthError ] = useState(false)

    const [ height, setHeight ] = useState(null)
    const [ heightError, setHeightError ] = useState(false)

    const [ fixedAspect, setFixedAspect ] = useState(true)

    const loaded = useRef(false)

    const reload = () => {
        if( coordinates == null )
        {
            return
        }
        
        let location = coordinates.location

        if( location == null )
        {
            return
        }
        
        setX( location.x )
        setXError( false )
        setY( location.y ) 
        setYError( false )
        setWidth( location.width )
        setWidthError( false )
        setHeight( location.height )
        setHeightError( false )

        setFixedAspect( coordinates.fixed_aspect_ratio )
        loaded.current = true
    }

    useEffect( () => {
        loaded.current = false
    },[selected])

    useEffect( () => {
        if( loaded.current )
        {
            return
        }

        reload()
    },[coordinates])


    useEffect( () => {

        if( x == null || y == null || width == null || height == null || fixedAspect == null )
        {
            return
        }

        let hasError = false;
        
        let l = {}

        try {
            l.x = parseFloat(x)
            if (isNaN(l.x)) {
                throw new Error("Invalid number format");
            }
            setXError(false)
        }
        catch 
        {
            setXError(true)
            hasError = true;
        }

        try {
            l.y = parseFloat(y)
            if (isNaN(l.y)) {
                throw new Error("Invalid number format");
            }

            setYError(false)
        }
        catch 
        {
            setYError(true)
            hasError = true;
        }

        try {
            l.width = parseFloat(width)
            if (isNaN(l.width)) {
                throw new Error("Invalid number format");
            }

            setWidthError(false)
        }
        catch 
        {
            setWidthError(true)
            hasError = true;
        }

        try {
            l.height = parseFloat(height)
            if (isNaN(l.height)) {
                throw new Error("Invalid number format");
            }

            setHeightError(false)
        }
        catch 
        {
            setHeightError(true)
            hasError = true;
        }

        //console.log( hasError )

        if( !hasError )
        {
            let c = {}
            c.location = l
            c.fixed_aspect_ratio = fixedAspect
            setCoordinates(c)
        }
        

    },[ x,y,width,height, fixedAspect ] )

    if( x == null || y == null || width == null || height == null )
    {
        return ( <></>)
    }

    return (
        <SettingsGroup name="Coordinates">
            <Control name="X"
                     ctrl={
                        <Form.Control value={x} 
                                  onChange={e => setX(e.target.value)} 
                                  size="sm"
                                  isInvalid={xError}
                        />
                     }
            />
            <Control name="Y"
                     ctrl={
                        <Form.Control value={y} 
                                  onChange={e => setY(e.target.value)} 
                                  size="sm"
                                  isInvalid={yError}
                        />
                     }
            />
            <Control name="Width"
                     ctrl={
                        <Form.Control value={width} 
                                  onChange={e => setWidth(e.target.value)} 
                                  size="sm"
                                  isInvalid={widthError}
                        />
                     }
            />
            <Control name="Height"
                     ctrl={
                        <Form.Control value={height} 
                                  onChange={e => setHeight(e.target.value)} 
                                  size="sm"
                                  isInvalid={heightError}
                        />
                     }
            />
            <Control name="Aspect Ratio"
                     ctrl={
                        <Form.Check checked={fixedAspect} 
                                  onChange={e => setFixedAspect(e.target.checked)} 
                                  size="sm"
                                  label="Fixed"
                        />
                     }
            />




        </SettingsGroup>
    )
}
