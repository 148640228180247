import { useEffect, useState, useRef } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../../Modules/General"

import FontModule from "../../Modules/FontModule"
import VariableModule from "../../Modules/VariableModule"

const predictVariables = [
    {name:'Target', key:'target', type:'R'},
    {name:'Evaluate', key:'evaluate', type:'R'},
    {name:'Stop vote', key:'disabled', type:'R'}
]

export default function PredictProperties( props )
{
    const dynoCfg = props.dynoCfg 
    const setDynoCfg = props.setDynoCfg 
    const selected = props.selected
    const reset = props.reset
    const modalOpen = props.modalOpen

    const [ font, setFont ] = useState(null)
    const [ hashtag, setHashtag ] = useState(null)
    const [ variables, setVariables ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)

    const reload = () => {
        let properties = dynoCfg.properties
        let cfg = ( properties.image_picker_cfg ) ? properties.image_picker_cfg : {}

        setFont( (cfg.font) ? cfg.font : {} )
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setHashtag((cfg.hashtag) ? cfg.hashtag : "")

    }

    useEffect(() => {
        reload()
    },[])

    useEffect(() => {
        if( variables == null || hashtag == null || font == null )
        {
            return
        }

        let data = {}
        data.variables = variables
        data.hashtag = hashtag
        data.font = font

        let cfg = structuredClone(dynoCfg)
        cfg.properties.predict_cfg = data

        setDynoCfg(cfg)

    },[ variables ])

    useEffect( () => {
        modalOpen.current = showModal
    },[ showModal ])


    return (
        <> 
            <SettingsGroup name="Data source"> 
                <Control name="Hashtag"
                     ctrl={
                        <Form.Control style={{ width:'100%'}} size="sm" 
                             value={hashtag} 
                             onChange={ e => setHashtag( e.target.value )}
                        />
                     }
                />
            </SettingsGroup>

            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={predictVariables} 
                            reset={reset}
            />
            }
        </>
    )


}
