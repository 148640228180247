import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"
import CoordinateModule from "../Modules/CoordinateModule"

const textVariables = [
    {name:'Value', key:'value', type:'R'},
]

export default function TextProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType
    const reset = props.reset

    const [ name, setName ] = useState(null)
    const [ placeHolder, setPlaceHolder ] = useState(null)
    const [ source, setSource ] = useState(null)
    const [ context, setContext ] = useState(null)
    const [ feed, setFeed ] = useState(null)
    const [ rank, setRank ] = useState(null)
    const [ hashtag, setHashtag ] = useState(null)
    const [ preset, setPreset ] = useState(null)

    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)
    const [ coordinates, setCoordinates ] = useState(null)
    //const [ location, setLocation ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        let properties = layersCfg.current[selected].properties

        if( properties == null )
        {
            properties = {}
        }
        
        let n = layersCfg.current[selected].name 
        setName( (n) ? n : "" )

        let p = layersCfg.current[selected].place_holder
        setPlaceHolder( (p) ? p : "" )
        
        setSource( (properties.source) ? properties.source : "streamer")
        setContext( (properties.context) ? properties.context : "username")
        setFeed( (properties.feed) ? properties.feed : "username" )
        setRank( (properties.rank) ? properties.rank : 0 )
        setHashtag( (properties.hashtag) ? properties.hashtag : "")
        setPreset( (properties.preset) ? properties.preset : "")
        setFont( (properties.font) ? properties.font : {})
        setVariables( (properties.variables) ? properties.variables : {} )

        let c = {}
        c.location = layersCfg.current[selected].location 
        c.fixed_aspect_ratio = layersCfg.current[selected].fixed_aspect_ratio 

        setCoordinates(c)
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        if( font == null || context == null || feed == null || 
            hashtag == null || preset == null || font == null || variables == null || 
            coordinates == null || name == null || placeHolder == null )
        {
            return
        }

        var data = {
            source: source,
            context: context,
            feed: feed,
            rank: rank,
            hashtag: hashtag,
            preset: preset,
            font: font, 
            variables: variables
        }

        layersCfg.current[selected].properties = data
        layersCfg.current[selected].name = name
        layersCfg.current[selected].place_holder = placeHolder
        layersCfg.current[selected].location = coordinates.location 
        layersCfg.current[selected].fixed_aspect_ratio = coordinates.fixed_aspect_ratio

        setLayers([...layers])

    },[ source, context, feed, rank, hashtag, preset, font, variables, coordinates, name, placeHolder ])

    if( source == null || font == null || context == null || feed == null || 
        hashtag == null || preset == null || font == null || variables == null || 
        coordinates == null )
    {
        return (
            <></>
        )
    }

    return (
        <>
            <SettingsGroup name="General">
                <Control name="Name"
                         ctrl={<Form.Control value={name} onChange={e => setName(e.target.value)} size="sm"/>
                         }
                />
                <Control name="Place holder"
                         ctrl={<Form.Control value={placeHolder} onChange={e => setPlaceHolder(e.target.value)} size="sm"/>
                         }
                />

            </SettingsGroup>
            <SettingsGroup name="Data source">
                { source &&
                <Control name="Source"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={source} 
                             onChange={ e => setSource( e.target.value )}
                        >
                            <option value="streamer">Streamer</option>
                            <option value="chatter_1">Big chatter (1 min)</option>
                            <option value="chatter_5">Big chatter (5 min)</option>
                            <option value="hashtag">Hashtag</option>
                            <option value="last_chat">Last chat</option>
                            <option value="preset">Preset</option>
                            <option value="variable">Variable</option>

                        </Form.Select>
                     }
                />
                }
                { source == "variable" &&
                <Control name="Context"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={context} 
                             onChange={ e => setContext( e.target.value )}
                        >
                            
                            <option value="username">Username</option>
                            <option value="raw">Raw</option>
                        </Form.Select>
                     }
                />
                }


                { ( source != "variable" || context == "username" ) &&

                <Control name="Value feed"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={feed} 
                             onChange={ e => setFeed( e.target.value )}
                        >
                            
                            <option value="username">Username</option>
                            <option value="message">Message</option>
                        </Form.Select>
                     }
                />
                }

                { ( source != "variable" ) &&
                <Control name="Rank"
                     ctrl={
                        <Form.Select style={{width:'100%'}} size="sm" 
                                     value={rank} 
                                     onChange={ e => setRank( parseInt(e.target.value) )}
                        >
                            <option value={0}>1</option>
                            <option value={1}>2</option>
                            <option value={2}>3</option>
                            <option value={3}>4</option>
                            <option value={4}>5</option>
                            <option value={5}>6</option>
                            <option value={6}>7</option>
                            <option value={7}>8</option>
                            <option value={8}>9</option>
                            <option value={9}>10</option>
                        </Form.Select>
                     }
                />
                }


                { ( source == "preset" ) &&
                <Control name="Preset"
                     ctrl={
                        <Form.Control value={preset} 
                                      onChange={e => setPreset(e.target.value)}
                                      style={{ width:'100%'}} 
                                      size="sm" 
                         />
                     }
                />

                }


                { ( source == "hashtag" ) &&
                <Control name="Hashtag"
                     ctrl={
                        <Form.Control value={hashtag} 
                                      onChange={e => setHashtag(e.target.value)}
                                      style={{ width:'100%'}} 
                                      size="sm" 
                         />
                     }
                />
                }

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
            />
            }
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={textVariables} 
                            reset={reset}
            />
            } 
            <CoordinateModule coordinates={coordinates} 
                              setCoordinates={setCoordinates}
                              selected={selected}
            />
        </>
    )
}
