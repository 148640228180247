import SekiroCfg from "./SekiroCfg"

export default function AiModulesCfg( props )
{
    const configType = props.configType 
    const modelCfg = props.modelCfg 
    const setModelCfg = props.setModelCfg
    const reset = props.reset

    if( configType.current.reference == "sekiro" )
    {
        return (
            <div style={{ height:'100%', width:'100%'}}>
                <SekiroCfg modelCfg={modelCfg} setModelCfg={setModelCfg} reset={reset} />
            </div>
        )
    }

    return (
        <div style={{ height:'100%', width:'100%'}}>
        </div>
    )
}
