import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/Form'

import { Control, SettingsGroup } from "../../Modules/General"

import FontModule from "../../Modules/FontModule"
import VariableModule from "../../Modules/VariableModule"

const randomPickerVariables = [
    {name:'Output', key:'output', type:'W'},
]

export default function RandomPickerProperties( props )
{
    const dynoCfg = props.dynoCfg 
    const setDynoCfg = props.setDynoCfg

    const modalOpen = props.modalOpen
    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ presetList, setPresetList ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)

    const [ showModal, setShowModal ] = useState(false)


    const [ modalText, setModalText ] = useState("")

    const reload = () => {
        let properties = dynoCfg.properties

        let cfg = ( properties.random_picker_cfg ) ? properties.random_picker_cfg : {}

        setFont( (cfg.font) ? cfg.font : {})
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setPresetList( (cfg.preset_list) ? cfg.preset_list : [] )
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || presetList == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.preset_list = presetList

        let cfg = structuredClone(dynoCfg)

        cfg.properties.random_picker_cfg = data
        setDynoCfg(cfg)
    },[ font, variables, presetList ])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])

    const addToPreset = () => {
        if( modalText == "" )
        {
            return 
        }

        let pp = structuredClone(presetList)
        pp.push( modalText )

        setPresetList(pp)

        setModalText("")
    }

    const removeFromPresetList = (idx) => {
        let pp = structuredClone(presetList)
        pp.splice(idx,1)
        setPresetList(pp)
    }

    return (
        <>
            <SettingsGroup name="Data source">
                <div className="d-flex align-items-center justify-content-center m-1" >
                    <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '100%', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    <Button onClick={ e => setShowModal(true) }
                            size="sm" 
                            style={{ width:'100%', textTransform:'none' }}
                            size="small"
                    > 
                        Edit preset list
                    </Button>
                    </div>
                </div>

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={randomPickerVariables}
                            reset={reset}
            />
            }
            
            <Modal show={showModal} onHide={e => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preset List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <InputGroup size="sm">
                            <Form.Control value={modalText} onChange={ e => setModalText(e.target.value)} />
                            <Button variant="contained"
                                    style={{ marginRight:'5px', textTransform:'none' }}
                                    onClick={addToPreset}
                            >
                                Add
                            </Button>
                        </InputGroup>
                    </div>
                    <div style={{ width:'100%', height:'250px', overflowY:'scroll', padding:'10px'}}>
                    { presetList &&
                    <>
                    { presetList.map( (pp,idx) => 
                    <div key={idx}>
                        {pp} [<a href="#" onClick={ e => removeFromPresetList(idx)}>Remove</a>]
                    </div>
                    )}
                    </>
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={e => setShowModal(false)} 
                            size="sm" style={{ marginRight:'5px', textTransform:'none' }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

