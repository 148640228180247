import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/Form'

import { Control, SettingsGroup } from "../../Modules/General"

import FontModule from "../../Modules/FontModule"
import VariableModule from "../../Modules/VariableModule"

const lottaryVariables = [
    {name:'Output', key:'output', type:'A'},
    {name:'Count', key:'count', type:'W'},
    {name:'Value', key:'value', type:'W'}
]

export default function LottaryProperties( props )
{
    const dynoCfg = props.dynoCfg 
    const setDynoCfg = props.setDynoCfg 
    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)
    const [ reference, setReference ] = useState(null)

    const reload = () => {
        let properties = dynoCfg.properties
        let cfg = ( properties.lottary_cfg ) ? properties.lottary_cfg : {}

        setFont( (cfg.font) ? cfg.font : {})
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setReference( (cfg.reference) ? cfg.reference : "" )
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || reference == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.reference = reference

        let cfg = structuredClone(dynoCfg)
        cfg.properties.lottary_cfg = data
        setDynoCfg(cfg)
    },[ font, variables, reference ])

    return (
        <>
            <SettingsGroup name="Data source">
                <Control name="Reference"
                     ctrl={
                        <Form.Control style={{ width:'100%'}} size="sm" 
                             value={reference} 
                             onChange={ e => setReference( e.target.value )}
                        />
                     }
                />

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={lottaryVariables} 
                            reset={reset}
            />
            } 
        </>
    )
}

