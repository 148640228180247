import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/Form'

import { Control, SettingsGroup } from "../../Modules/General"

import FontModule from "../../Modules/FontModule"
import VariableModule from "../../Modules/VariableModule"

const twitchAudiencePickerVariables = [
    {name:'Output', key:'output', type:'W'},
    {name:'Count', key:'count', type:'W'},
]

export default function TwitchAudiencePickerProperties( props )
{
    const dynoCfg = props.dynoCfg 
    const setDynoCfg = props.setDynoCfg

    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ hashtag, setHashtag ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)

    const reload = () => {
        let properties = dynoCfg.properties
        let cfg = (properties.twitch_audience_picker_cfg) ? properties.twitch_audience_picker_cfg : {}

        setFont((cfg.font) ? cfg.font : {})
        setVariables((cfg.variables) ? cfg.variables : {})
        setHashtag((cfg.hashtag) ? cfg.hashtag : "")
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || hashtag == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.hashtag = hashtag

        let cfg = structuredClone(dynoCfg)
        cfg.properties.twitch_audience_picker_cfg = data
        setDynoCfg(cfg)
    },[ font, variables, hashtag ])

    return (
        <>
            <SettingsGroup name="Data source"> 
                <Control name="Hashtag"
                     ctrl={
                        <Form.Control style={{ width:'100%'}} size="sm" 
                             value={hashtag} 
                             onChange={ e => setHashtag( e.target.value )}
                        />
                     }
                />
            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={twitchAudiencePickerVariables}
                            reset={reset}
            />
            } 
        </>
    )
}

