 import { useState, useEffect, useRef, useLayoutEffect } from "react"

import LoadingProgress from '../SpiceUI/LoadingProgress'
import Spinner from 'react-bootstrap/Spinner';
//import Button from 'react-bootstrap/Button'
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import { backendAccessPost, backendAccessGet } from '../../Utils/Authentication'
import { componentCapture } from "../Misc/Capture";
import Loading from '../Loading/Loading'

import Select from '../Misc/Select'

import { SlArrowRight, SlArrowDown } from "react-icons/sl";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { LuTrash2 } from "react-icons/lu";
import { FaArrowUpLong } from "react-icons/fa6";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { render } from "react-dom";
import { LiaPepperHotSolid } from "react-icons/lia";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  '&:hover': {
    backgroundColor: grey[200],
  },
}));

export function binaryStringToArrayBuffer(binaryString) {
    const length = binaryString.length;
    const buffer = new ArrayBuffer(length);
    const uint8Array = new Uint8Array(buffer);

    for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }

    return buffer;
}

export function ModuleSelector( props )
{
    const check = props.check 
    const childTrue = props.childTrue 
    const childFalse = props.childFalse

    if( check )
    {
        return (
            <>
                { childTrue }
            </>
        )
    }

    return (
        <>
            { childFalse }
        </>
    )
}

export function AvatarCharacter(props) {
    const meta = props.meta
    const data = props.data

    const renderCfg = props.renderCfg

    const setHair = props.setHair
    const setEyebrows = props.setEyebrows
    const setEye = props.setEye
    const setMouth = props.setMouth
    const setNose = props.setNose

    const [ columnTemplate, setColumnTemplate ] = useState("auto")
    const itemWidth = 100
    const [ dataArr, setDataArr ] = useState(null)

    const divRef = useRef(null)

    const determineColumnTemplate = () => {
        let ncols = Math.floor(divRef.current.clientWidth / itemWidth)

        if( ncols == 0 )
        {
            ncols = 1
        }
        
        let arr = []
        for( let i=0 ; i<ncols ; i++ )
        {
            arr.push("auto")
        }

        let str = arr.join(" ")
        setColumnTemplate(str)
    }

    useEffect( () => {
        determineColumnTemplate() 

        window.addEventListener('resize', determineColumnTemplate);
        return () => window.removeEventListener('resize', determineColumnTemplate);
    },[])

    useEffect( () => {
        let dlocal = []
        let darr = []

        let arr = []

        for( let [key,value] of Object.entries(data[meta.value]) )
        {
            let d = { key:key, value: value }
            darr.push( d )
        }

        setDataArr( darr )
        determineColumnTemplate()
    },[meta])

    return (
        <div style={{ height:'100%', width:'100%', overflowY:'scroll'}}>
            <div ref={divRef}
                 style={{ display:'grid', gridAutoColumns:'110px', 
                          gridTemplateColumns:columnTemplate, width:'100%', marginTop:'5px', 
                          overflowY:'scroll', paddingLeft:'10px', 
                          paddingRight:'10px' }}>
                { dataArr &&
                <>
                    { dataArr.map( (item,idx) => 
                    <div style={{ display:'inline-grid', width: '100px', height: '100px', marginBottom:'20px'}} key={idx}>
                        <AvatarAssetRender parent="character" 
                                           code={item.key} 
                                           group={meta.value} 
                                           asset={item.value} 
                                           renderCfg={renderCfg}
                                           setHair={setHair} 
                                           setEyebrows={setEyebrows} 
                                           setEye={setEye} 
                                           setMouth={setMouth} 
                                           setNose={setNose}
                        />
                    </div>
                    )} 
                </>
                }
            </div>
        </div>
    )
}

export function AvatarAccessories(props) {
    const meta = props.meta
    const data = props.data

    const renderCfg = props.renderCfg
    const setAccessory = props.setAccessory
    
    const [ columnTemplate, setColumnTemplate ] = useState("auto")
    const itemWidth = 100
    const [ dataArr, setDataArr ] = useState(null)

    const divRef = useRef(null)

    const determineColumnTemplate = () => {
        let ncols = Math.floor(divRef.current.clientWidth / itemWidth)

        if( ncols == 0 )
        {
            ncols = 1
        }
        
        let arr = []
        for( let i=0 ; i<ncols ; i++ )
        {
            arr.push("auto")
        }

        let str = arr.join(" ")
        setColumnTemplate(str)
    }

    useEffect( () => {
        determineColumnTemplate() 

        window.addEventListener('resize', determineColumnTemplate);
        return () => window.removeEventListener('resize', determineColumnTemplate);
    },[])

    useEffect( () => {
        let dlocal = []
        let darr = []

        let arr = []

        for( let [key,value] of Object.entries(data[meta.value]) )
        {
            let d = { key:key, value: value }
            darr.push( d )
        }

        setDataArr( darr )
        determineColumnTemplate()
    },[meta])

    return (
        <div style={{ height:'100%', width:'100%', overflowY:'scroll' }}> 
            <div ref={divRef}
                 style={{ display:'grid', gridAutoColumns:'110px', 
                          gridTemplateColumns: columnTemplate, 
                          width:'100%', marginTop:'5px', 
                          overflowY:'scroll', paddingLeft:'10px', 
                          paddingRight:'10px' }}>
                { dataArr &&
                <>
                    { dataArr.map( (item,idx) => 
                    <div style={{ display:'inline-grid', width: '100px', height: '100px', marginBottom:'20px'}} key={idx}>
                        <AvatarAssetRender parent="accessories" 
                                           code={item.key} 
                                           group={meta.value} 
                                           asset={item.value} 
                                           renderCfg={renderCfg}
                                           setAccessory={setAccessory}
                        />
                    </div>
                    )}
                </>
                }
            </div>
        </div>
    )
}

export function AvatarAssetRenderCharacter(props) {

    const parent = props.parent
    const group = props.group
    const assetDefault = props.assetDefault
    const assetOverwrite = props.assetOverwrite

    const type = props.type
    const zIndex = props.zIndex

    if (parent == 'character' && group == type) {
        return (
            <img style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                zIndex: zIndex,
                height: '100%'
            }}
                src={'/images/avatar/' + assetOverwrite}
            />
        )
    }
    if (assetDefault == null) {
        return (
            <></>
        )
    }
    return (

        <img style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            zIndex: zIndex,
            height: '100%',
        }}
            src={'/images/avatar/' + assetDefault}
        />
    )
}

export function AvatarAssetRenderAccessories(props) {

    const parent = props.parent
    const group = props.group
    const assetDefault = props.assetDefault
    const assetOverwrite = props.assetOverwrite

    const type = props.type
    const zIndex = props.zIndex

    if (parent == 'accessories') {
        return (
            <img style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                zIndex: zIndex,
                height: '100%'
            }}
                src={'/images/avatar/' + assetOverwrite}
            />
        )
    }
    if (assetDefault == null) {
        return (
            <></>
        )
    }
    return (
        <img style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            zIndex: zIndex,
            height: '100%'
        }}
            src={'/images/avatar/' + assetDefault}
        />
    )
}

export function AvatarAssetRender(props) {
    const parent = props.parent
    const code = props.code
    const group = props.group
    const asset = props.asset

    const renderCfg = props.renderCfg 

    const setHair = props.setHair
    const setEyebrows = props.setEyebrows
    const setEye = props.setEye
    const setMouth = props.setMouth
    const setNose = props.setNose

    const setAccessory = props.setAccessory

    const setImage = () => {

        if (parent == "character") {
            if (group == "hair") {
                setHair(code)
            }
            else if (group == "eyebrows") {
                setEyebrows(code)
            }
            else if (group == "eye") {
                setEye(code)
            }
            else if (group == "mouth") {
                setMouth(code)
            }
            else if (group == "nose") {
                setNose(code)
            }
        }
        else if (parent == "accessories") {
            setAccessory([group, code])
        }
    }

    return (

        <div style={{ width: 'auto', 
                      height: '100%', 
                      position: 'relative',
                      aspectRatio:0.890125, 
                      cursor:'pointer'}} 
            onClick={setImage}
        >
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.hair0}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                type="hair"
                zIndex="1"
            />

            {/* {facade &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '2',
                    height: '100%'
                }}
                    src={'/images/avatar/' + facade}
                />
            } */}
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.eyebrows}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                type="eyebrows"
                zIndex="3"
            />
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.eye}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                type="eye"
                zIndex="3"
            />
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.mouth}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                type="mouth"
                zIndex="3"
            />
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.nose}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                type="nose"
                zIndex="3"
            />
            <AvatarAssetRenderAccessories
                assetDefault={renderCfg.accessory}
                assetOverwrite={asset[0]}
                parent={parent}
                group={group}
                zIndex="5"
            />
            <AvatarAssetRenderCharacter
                assetDefault={renderCfg.hair1}
                assetOverwrite={asset[1]}
                parent={parent}
                group={group}
                setHair={setHair}
                type="hair"
                zIndex="4"
            />
        </div>
    )
}

export function AvatarRender(props) {
    const renderCfg = props.renderCfg
    const hair0 = props.hair0
    const facade = props.facade
    const eyebrows = props.eyebrows
    const eye = props.eye
    const mouth = props.mouth
    const nose = props.nose
    const accessory = props.accessory
    const hair1 = props.hair1

    const captureRef = props.captureRef

    if( Object.keys(renderCfg) == 0 )
    {
        return (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ width: 'auto', height: '100%',
                      position: 'relative', aspectRatio:0.890125 }}
            >
                Create your avatar!!
                {/*<img style={{ position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              zIndex: '1',
                              height: '100%'
                     }}
                     src={"/images/avatar-profile-question.png"}
                />*/}
            </div>
        )
    }

    return (
        <div style={{ width: 'auto', height: '100%', 
                      position: 'relative', aspectRatio:0.890125 }} 
             ref={captureRef}>
            {renderCfg.hair0 &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '1',
                    height: '100%'
                }}
                    src={'/images/avatar/' + renderCfg.hair0}
                />
            }
            {renderCfg.facade &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '2',
                    height: '100%'
                }}
                    src={'/images/avatar/' + renderCfg.facade}
                />
            }
            {renderCfg.eyebrows &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '3',
                    height: '100%'
                }}
                    src={'/images/avatar/' + renderCfg.eyebrows}
                />
            }
            {renderCfg.eye &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '3',
                    height: '100%'
                }}
                    src={'/images/avatar/' + renderCfg.eye}
                />
            }
            {renderCfg.mouth &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '3',
                    height: '100%'
                }} src={'/images/avatar/' + renderCfg.mouth} />
            }
            {renderCfg.nose &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '3',
                    height: '100%'
                }} src={'/images/avatar/' + renderCfg.nose} />
            }
            {renderCfg.accessory &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '5',
                    height: '100%'
                }} src={'/images/avatar/' + renderCfg.accessory} />
            }
            {renderCfg.hair1 &&
                <img style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '4',
                    height: '100%'
                }} src={'/images/avatar/' + renderCfg.hair1} />
            }
        </div>
    )
}

export function CategorySelect(props) {
    const assets = props.assets
    const selectedCategory = props.selectedCategory
    const setSelectedCategory = props.setSelectedCategory
    const setSelectedMeta = props.setSelectedMeta
    const saveAvatar = props.saveAvatar
    const accountType = props.accountType

    const select = (idx, item) => {
        setSelectedCategory(idx);
        setSelectedMeta(item)
    }

    const hasBorderTop = ( idx, count ) => {
        if( idx == 0 )
        {
            return false 
        }
        return true
    }

    const hasBorderBottom = ( idx, count ) => {
        if( idx == count-1 )
        {
            return false 
        }
        return true
    }

    const premiumLogo = ( item ) => {

        if( item.value == "basic" || item.value == "premium" )
        {
            return (
                <LiaPepperHotSolid style={{ marginLeft:'5px'}}/>
            )
        }

        return (<></>)
    }

    const isDisabled = ( item ) => {
        if( item.value == "basic" || item.value == "premium" )
        {
            return accountType == "mild"
        }

        return false;

    }
    
    return (
        <div style={{ width: '100%' }}>
            <ButtonGroup orientation="vertical" variant="text" disableRipple >
                {assets.meta.map((item, idx) => (
                <Button onClick={(e) => select(idx, item)}
                        style={{ borderLeftColor: '#fff', 
                                 borderRightColor: '#fff', 
                                 borderTopColor: (hasBorderTop(idx,assets.meta.length)) ? '#ccc' : '#fff', 
                                 borderBottomColor: (hasBorderBottom(idx,assets.meta.length)) ? '#ccc' : '#fff', 
                                 display: 'flex', 
                                 justifyContent: 'space-between', 
                                 backgroundColor: (selectedCategory == idx) ? '#EFEFEF' : '#fff', 
                                 color: (isDisabled(item)) ? 'grey' : '#000', 
                                 textAlign: 'left', 
                                 width: 120, 
                                 paddingBottom: 13, 
                                 itemsAlign: 'center',
                                 fontSize: 13,
                                 textTransform: 'none', }}
                        disabled={ isDisabled(item) }
                >
                    <span className="mr-3 pt-2"> {item.name} { premiumLogo(item) }</span>
                    {(selectedCategory == idx) ? <SlArrowRight size={10} className="ml-3 mt-3" /> : <SlArrowDown size={10} className="ml-3 mt-3" />}
                </Button>
                ))}
            </ButtonGroup>
        </div>
    )
}

export function AssetViewer(props) {
    const assets = props.assets
    const selectedMeta = props.selectedMeta

    const renderCfg = props.renderCfg

    const setHairCfg = props.setHairCfg
    const setEyebrowsCfg = props.setEyebrowsCfg
    const setEyeCfg = props.setEyeCfg
    const setMouthCfg = props.setMouthCfg
    const setNoseCfg = props.setNoseCfg
    const setAccessoryCfg = props.setAccessoryCfg
    const setFacadeCfg = props.setFacadeCfg

    return (
        <div style={{ width: '100%', height: '100%' }} >
            {selectedMeta &&
            <div style={{ width: '100%', borderRadius: 5, padding: 5, height: '420px' }} >
                {selectedMeta.category == "character" &&
                <AvatarCharacter meta={selectedMeta} 
                                 data={assets.assets.character}
                                 renderCfg={renderCfg}
                                 setHair={setHairCfg}
                                 setEyebrows={setEyebrowsCfg}
                                 setEye={setEyeCfg}
                                 setMouth={setMouthCfg}
                                 setNose={setNoseCfg}
                />
                }
                {selectedMeta.category == "accessories" &&
                <AvatarAccessories meta={selectedMeta} 
                                   data={assets.assets.accessories}
                                   renderCfg={renderCfg}
                                   setAccessory={setAccessoryCfg}
                />
                }
                {/* {selectedMeta.category == "facade" &&
                <AvatarFacade meta={selectedMeta} data={assets.assets.facade}
                              setFacade={setFacadeCfg} />
                } */}
            </div>
            }
        </div>
    )
}

export function AvatarDropdownButton( props )
{
    const avatar = props.avatar
    const editAvatar = props.editAvatar 
    const setAsDefault = props.setAsDefault 
    const removeAvatar = props.removeAvatar

    return (
        <div style={{ height:'80px', width:'200px'}}>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{ height:'100%', width:'auto', aspectRatio:1, float:'left' }}>
                { avatar.avatar_img &&
                <img style={{ maxHeight:'100%', maxWidth:'100%'}}
                     src={"data:image/png;base64, " + avatar.avatar_img}
                     onClick={editAvatar}
                />
                }
                { avatar.avatar_img == null &&
                <img style={{ maxHeight:'100%', maxWidth:'100%'}}
                     src={"images/avatar-profile-question.png"}
                     onClick={editAvatar}
                />
                }
            </div>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{ height:'100%', width:'calc(100% - 80px)', float:'right' }}>
                <FaRegEdit style={{ cursor:'pointer' }} onClick={editAvatar} />
                <ModuleSelector check={ avatar.is_default == 1 }
                                childTrue={<MdOutlineCheckBox style={{ fontSize:'17px' }}/>}
                                childFalse={<MdOutlineCheckBoxOutlineBlank style={{ fontSize:'17px'}}
                                                                           onClick={ e => setAsDefault(avatar.avatar_id)} />}
                />
                <LuTrash2 onClick={ e => removeAvatar(avatar.avatar_id)} />
                {/*<ModuleSelector check={ avatar.is_default == 1 }
                                childTrue={<></>}
                                childFalse={<LuTrash2 onClick={ e => removeAvatar(avatar.avatar_id)} />}
                />*/}
            </div>

        </div>
    )
}

export function AvatarDropdown( props )
{
    const avatars = props.avatars
    const setSelectedAvatar = props.setSelectedAvatar
    const createAvatar = props.createAvatar
    const setDefault = props.setDefault
    const removeAvatar = props.removeAvatar
    const maxAvatars = props.maxAvatars

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <ColorButton style={{ textTransform:'none'}}
                    size="small"
                    onClick={handleClick}
                    color="success">
                My Avatars
            </ColorButton>
            <Tooltip title="Both streamers and viewers can create avatars and their avatars can be 
                            displayed in different widgets via the streamer's Spice.Stream application. Different 
                                            avatars can be used in different context. Upgrade your account to create more 
                                            avatars."
                     arrow>
                <div style={{ float:'right', marginLeft:'5px' }}>
                    <HiOutlineExclamationCircle />
                </div>
            </Tooltip>


            <Menu anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                <MenuItem onClick={createAvatar}>
                    <div className="d-flex justify-content-center align-items-center" 
                         style={{ color : (avatars.length >= maxAvatars) ? '#D7D7D7' : '#000000',
                                  height:'40px'}}>
                        Create a new avatar ({avatars.length}/{maxAvatars}) 
                    </div>
                </MenuItem>
                { avatars.map( (avatar,idx) => 
                <MenuItem key={idx}>
                    <AvatarDropdownButton avatar={avatar} editAvatar={ e => setSelectedAvatar(avatar.avatar_id) } 
                                          setAsDefault={setDefault} removeAvatar={removeAvatar}
                    /> 
                </MenuItem>
                )}


            </Menu>
        </div>
    )
}

export function AvatarTool(props) {
    const assets = props.assets
    const assetsAll = props.assetsAll
    const config = props.config
    const initialized = props.initialized
    const setConfig = props.setConfig
    const snapshot = props.snapshot
    const setSnapshot = props.setSnapshot
    const avatars = props.avatars
    const selectedAvatar = props.selectedAvatar
    const setSelectedAvatar = props.setSelectedAvatar
    const saveAvatar = props.saveAvatar
    const createAvatar = props.createAvatar
    const discardAvatar = props.discardAvatar
    const setDefault = props.setDefault
    const removeAvatar = props.removeAvatar
    const hasChange = props.hasChange
    const maxAvatars = props.maxAvatars
    const accountType = props.accountType

    const viewRef = useRef(null)
    
    const [category, setCategory] = useState("character")

    const [selectedCategory, setSelectedCategory] = useState(-1)
    const [selectedMeta, setSelectedMeta] = useState(null)
    const [region, setRegion] = useState("hair")

    const [assetImages, setAssetImages] = useState({})

    const [ faceCfg, setFaceCfg ] = useState(null) 

    const [hairCfg, setHairCfg] = useState(null)
    const [facadeCfg, setFacadeCfg] = useState(null)
    const [eyebrowsCfg, setEyebrowsCfg] = useState(null)
    const [eyeCfg, setEyeCfg] = useState(null)
    const [mouthCfg, setMouthCfg] = useState(null)
    const [noseCfg, setNoseCfg] = useState(null)
    const [accessoryCfg, setAccessoryCfg] = useState(null)

    const [ renderCfg, setRenderCfg ] = useState(null)

    useEffect(() => {
        if( assets.meta.length > 0 )
        {
            setSelectedCategory(0)
            setSelectedMeta( assets.meta[0] )
        }
    }, [])

    useEffect( () => {
        if( !hairCfg )
        {
            return
        }

        if( !assets.assets['character']['hair'][hairCfg] )
        {
            return
        }

        let rcfg = structuredClone(renderCfg)
        rcfg.hair0 = assets.assets['character']['hair'][hairCfg][0]
        rcfg.hair1 = assets.assets['character']['hair'][hairCfg][1]

        let cfg = structuredClone(config)
        cfg.hair = hairCfg 

        setRenderCfg(rcfg)
        setConfig(cfg)

    },[hairCfg] )

    useEffect( () => {
        if( !eyebrowsCfg )
        {
            return 
        }
        
        if( !assets.assets['character']['eyebrows'][eyebrowsCfg] )
        {
            return
        }

        let rcfg = structuredClone(renderCfg)
        rcfg.eyebrows = assets.assets['character']['eyebrows'][eyebrowsCfg][0]

        let cfg = structuredClone(config)
        cfg.eyebrows = eyebrowsCfg 
        
        setRenderCfg(rcfg)
        setConfig(cfg)
    }, [eyebrowsCfg])

    useEffect( () => {
        if( !eyeCfg )
        {
            return 
        }
        
        if( !assets.assets['character']['eye'][eyeCfg] )
        {
            return
        }

        let rcfg = structuredClone(renderCfg)
        rcfg.eye = assets.assets['character']['eye'][eyeCfg][0]

        let cfg = structuredClone(config)
        cfg.eye = eyeCfg 
        
        setRenderCfg(rcfg)
        setConfig(cfg)
    },[eyeCfg])

    useEffect( () => {
        if( !mouthCfg )
        {
            return 
        }
        
        if( !assets.assets['character']['mouth'][mouthCfg] )
        {
            return
        }

        let rcfg = structuredClone(renderCfg)
        rcfg.mouth = assets.assets['character']['mouth'][mouthCfg][0]

        let cfg = structuredClone(config)
        cfg.mouth = mouthCfg 
        
        setRenderCfg(rcfg)
        setConfig(cfg)
    },[mouthCfg])

    useEffect( () => {
        if( !noseCfg )
        {
            return 
        }
        
        if( !assets.assets['character']['nose'][noseCfg] )
        {
            return
        }

        let rcfg = structuredClone(renderCfg)
        rcfg.nose = assets.assets['character']['nose'][noseCfg][0]

        let cfg = structuredClone(config)
        cfg.nose = noseCfg 
        
        setRenderCfg(rcfg)
        setConfig(cfg)
    },[noseCfg])

    useEffect( () => {
        if( !accessoryCfg )
        {
            return 
        }

        var group = accessoryCfg[0]
        var name = accessoryCfg[1]

        let rcfg = structuredClone(renderCfg)
        rcfg.accessory = assets.assets['accessories'][group][name][0]

        let cfg = structuredClone(config)
        cfg.accessory = accessoryCfg

        setRenderCfg(rcfg)
        setConfig(cfg)

    },[accessoryCfg])

    useEffect(() => {
        if( initialized.current )
        {
            return
        }

        let rcfg = {}

        if( config.hair )
        {
            let hairCfg = config.hair
            rcfg.hair0 = assets.assets['character']['hair'][hairCfg][0]
            rcfg.hair1 = assets.assets['character']['hair'][hairCfg][1]
        }

        if( config.eyebrows )
        {
            let eyebrowsCfg = config.eyebrows
            rcfg.eyebrows = assets.assets['character']['eyebrows'][eyebrowsCfg][0]
        }

        if( config.eye )
        {
            let eyeCfg = config.eye 
            rcfg.eye = assets.assets['character']['eye'][eyeCfg][0]
        }

        if( config.mouth )
        {
            let mouthCfg = config.mouth
            rcfg.mouth = assets.assets['character']['mouth'][mouthCfg][0]
        }

        if( config.nose )
        {
            let noseCfg = config.nose 
            rcfg.nose = assets.assets['character']['nose'][noseCfg][0]
        }

        if( config.accessory )
        {
            let accessoryCfg = config.accessory 
            let group = accessoryCfg[0]
            let name = accessoryCfg[1]

            rcfg.accessory = assets.assets['accessories'][group][name][0]
        }

        initialized.current = true

        setRenderCfg(rcfg)
    }, [config])

    useEffect(() => {
        if( viewRef.current != null )
        {
            componentCapture(viewRef).then(res => {
                setSnapshot(res)
            })
        }
    }, [renderCfg])

    return (
        <div style={{ height: '500px', width: '1100px' }}>
            <div style={{ height:'100%', width:'450px', float:'left' }}>
                <div className="d-flex justify-content-center align-items-center" 
                     style={{ width: '100%', height:'50px' }}>
                    { avatars.length > 0 &&
                    <ModuleSelector check={hasChange}
                                    childFalse={
                                        <AvatarDropdown avatars={avatars}
                                                        setSelectedAvatar={setSelectedAvatar}
                                                        createAvatar={createAvatar}
                                                        setDefault={setDefault}
                                                        removeAvatar={removeAvatar}
                                                        maxAvatars={maxAvatars}
                                        />
                                    }
                                    childTrue={
                                        <ButtonGroup size="sm">
                                            <Button variant="danger" onClick={saveAvatar}> Save </Button>
                                            <Button variant="primary" onClick={discardAvatar}> Discard </Button>
                                        </ButtonGroup>
                                    }
                    />
                    }
                </div>
                <div  style={{ width: '100%', height: '450px', padding:'10px' }}>
                    { renderCfg != null &&
                    <ModuleSelector check={ selectedAvatar != null }
                                    childTrue={
                                        <div className="d-flex justify-content-center align-items-center"
                                             style={{height:'100%', width:'100%'}}>
                                            <AvatarRender renderCfg={renderCfg}
                                                          captureRef={viewRef}
                                            />
                                        </div>
                                    }
                                    childFalse={
                                        <div className="d-flex justify-content-center align-items-center"
                                              style={{height:'100%', width:'100%'}}
                                        >
                                            <Button style={{ textTransform:'none'}}
                                                    onClick={createAvatar}
                                            >
                                                Click here to create an avatar
                                            </Button>
                                        </div>
                                        }
                    />
                    }
                </div>
            </div>
            <div style={{ width: '200px', height: '450px', float: 'left' }}>
                <div style={{ width: '100%', height:'50px' }}>
                </div>
                <div className="d-flex justify-content-center align-items-center" 
                     style={{ width: '100%', height: '450px' }}
                >
                    <CategorySelect assets={assets}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    setSelectedMeta={setSelectedMeta}
                                    saveAvatar={saveAvatar}
                                    accountType={accountType}
                    />
                </div>
            </div>
            <div style={{ width:'calc(100% - 650px)', height: '100%', float: 'right' }}>
                <div style={{ width: '100%', height:'50px' }}>
                </div>
                <div className="d-flex justify-content-center align-items-center" 
                     style={{ width: '100%', height: '450px', padding:'10px' }}>
                    <AssetViewer selectedMeta={selectedMeta}
                                 assets={assets}
                                 setHairCfg={setHairCfg}
                                 setEyebrowsCfg={setEyebrowsCfg}
                                 setEyeCfg={setEyeCfg}
                                 setNoseCfg={setNoseCfg}
                                 setMouthCfg={setMouthCfg}
                                 setAccessoryCfg={setAccessoryCfg}
                                 setFacadeCfg={setFacadeCfg}
                                 renderCfg={renderCfg}
                    />
                </div>
            </div>
        </div>
    )
}

export default function Avatar(props) {
    const setAvatarImage = props.setAvatarImage
    const userConfig = props.userConfig
    const accountType = props.accountType

    const [loading, setLoading] = useState(true)
    const [progressValue, setProgressValue] = useState(0)
    const [progressMax, setProgressMax] = useState(0)

    const [assets, setAssets] = useState(null)
    const [avatars, setAvatars] = useState([])
    const [ selectedAvatar, setSelectedAvatar ] = useState(null)

    const configJson = useRef(null)
    const [config, setConfig] = useState({})
    const [snapshot, setSnapshot] = useState(null)
    const initialized = useRef(false)

    const [hasChange, setHasChange] = useState(false)

    const firstTime = useRef(true)

    const [ maxAvatars, setMaxAvatars ] = useState(userConfig.config.max_avatars)

    const loadAssets = async () => {
        let res = await backendAccessGet('/api/avatar/fetch')

        if (res == null) {
            alert("Failed to load avatar configurations")
            return
        }

        let assetsLocal = {}
        let metaLocal = []

        for (let [key, value] of Object.entries(res.meta)) {
            for (let v of value) {
                let v_ = structuredClone(v)
                v_.category = key

                metaLocal.push(v_)
            }
        }

        for (let asset of res.assets) {
            let name = asset.name

            let kind = asset.kind
            let category = asset.category

            if (assetsLocal[kind] == null) {
                assetsLocal[kind] = {}
            }

            if (assetsLocal[kind][category] == null) {
                assetsLocal[kind][category] = {}
            }

            assetsLocal[kind][category][name] = asset['data']
        }

        setAssets({ meta: metaLocal, assets: assetsLocal })
        setAvatars( res.avatars )
    }

    useEffect(() => {
        loadAssets().then(res => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if( avatars.length == 0 )
        {
            return
        }

        if( firstTime.current )
        {
            for( let avatar of avatars )
            {
                if( avatar.is_default )
                {
                    setSelectedAvatar( avatar.avatar_id )
                    break
                }
            }

            firstTime.current = false
        }
    },[avatars])

    const getAvatarIndex = ( avatar_id ) => {
        let avatar_idx = -1 

        let index = 0
        for( let avatar of avatars )
        {
            if( avatar.avatar_id == avatar_id )
            {
                avatar_idx = index
            }

            index += 1
        }

        return avatar_idx
    }

    const selectRandom = ( items ) => {
        const random_index = Math.floor(Math.random() * items.length)
        return items[random_index]
    }

    const randomAvatar = () => {
        let cfg = {}

        if( assets != null )
        {
            cfg.eye = selectRandom( Object.keys( assets.assets.character.eye ) )
            cfg.eyebrows = selectRandom( Object.keys( assets.assets.character.eyebrows ) )
            cfg.hair = selectRandom( Object.keys( assets.assets.character.hair ) )
            cfg.mouth = selectRandom( Object.keys( assets.assets.character.mouth ) )
            cfg.nose = selectRandom( Object.keys( assets.assets.character.nose ) )
        }

        return cfg
    }

    useEffect( () => {
        if( selectedAvatar == null )
        {
            return
        }

        let avatar_idx = getAvatarIndex(selectedAvatar) 

        if( avatar_idx == -1 )
        {
            return
        }

        initialized.current = false
        let cfg = structuredClone(avatars[avatar_idx].avatar_config)
        configJson.current = JSON.stringify(cfg)
        if( Object.keys(cfg).length == 0 )
        {
            cfg = randomAvatar()
        }

        setConfig(cfg)
    },[selectedAvatar])


    useEffect(() => {
        if (configJson.current == null) {
            return
        }

        let cfgJson = JSON.stringify(config)

        if (cfgJson == configJson.current) {
            setHasChange(false)
        }
        else {
            setHasChange(true)
        }
    }, [config])

    const createAvatar = () => {
        var data = {}
        backendAccessPost('/api/avatar/create/', data).then( res => {
            if( res == null )
            {
                alert("Something went wrong.")
                return
            }

            setAvatars( res.avatars )
            setSelectedAvatar( res.uid )
        })
    }

    const saveAvatar = () => {
        var data = {}
        data['avatar_id'] = selectedAvatar
        data['config'] = config
        data['img'] = snapshot

        let avatar_idx = getAvatarIndex(selectedAvatar)

        //var str = snapshot.img
        //setAvatarImage(str)

        backendAccessPost('/api/avatar/update/', data).then(res => {
            if( res == null )
            {
                alert("Something went wrong.")
                return
            }

            if( avatars[avatar_idx].is_default == 1 )
            {
                setAvatarImage( snapshot.img )
            }

            configJson.current = JSON.stringify(config)
            setHasChange(false)
            setAvatars(res.avatars)

            let checksum_md5 = res.avatars[avatar_idx].checksum_md5 
            let tag = res.avatars[avatar_idx].tag

            let dd = { checksum_md5:checksum_md5, img:snapshot.img, tag:tag }
            localStorage.avatar = JSON.stringify(dd)
        })
    }

    const discardAvatar = () => {
        let cfg = JSON.parse( configJson.current )
        initialized.current = false
        setConfig(cfg)
    }

    const setDefault = ( avatar_id ) => {
        let data = {}
        data['avatar_id'] = avatar_id 

        backendAccessPost('/api/avatar/set-default/', data).then( res => {
            if( res == null )
            {
                alert("Something went wrong.")
                return
            }

            let avatar_idx = getAvatarIndex( avatar_id )
            let avatar = res.avatars[avatar_idx]

            setAvatarImage( avatar.avatar_img )
            setAvatars(res.avatars)

        })
    }

    const removeAvatar = ( avatar_id ) => {
        let data = {}
        data['avatar_id'] = avatar_id 

        backendAccessPost('/api/avatar/remove/', data).then( res => {
            if( res == null )
            {
                alert("Something went wrong.")
                return
            }
            setAvatars(res.avatars)
            setSelectedAvatar(null)
        })
    }

    const download = () => {
        var buffer_bs = atob(snapshot.img)
        var buffer = binaryStringToArrayBuffer(buffer_bs)

        var b = new Blob([buffer]);
        var csvURL = window.URL.createObjectURL(b);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'avatar.png');
        tempLink.click();
    }

    useEffect( () => {
        setMaxAvatars( userConfig.config.max_avatars )
    },[userConfig])

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center" 
             style={{ height: '100%', width: '100%', padding: 10  }}>

            <AvatarTool assets={assets} 
                        config={config} 
                        setConfig={setConfig}
                        initialized={initialized}
                        snapshot={snapshot} 
                        setSnapshot={setSnapshot}
                        avatars={avatars}
                        selectedAvatar={selectedAvatar}
                        setSelectedAvatar={setSelectedAvatar}
                        createAvatar={createAvatar} 
                        saveAvatar={saveAvatar}
                        discardAvatar={discardAvatar}
                        setDefault={setDefault}
                        removeAvatar={removeAvatar}
                        hasChange={hasChange}
                        maxAvatars={maxAvatars}
                        accountType={accountType}
            />
        </div>
    )
}
