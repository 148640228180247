import { useEffect, useState, useRef } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../../Modules/General"

import FontModule from "../../Modules/FontModule"
import VariableModule from "../../Modules/VariableModule"

import { parseGIF, decompressFrames } from "gifuct-js";

const imagePickerVariables = [
    {name:'Value', key:'value', type:'R'},
]

export function ImageListModal( props )
{
    const showModal = props.showModal 
    const setShowModal = props.setShowModal

    const images = props.images 
    const setImages = props.setImages
    
    const [ value, setValue ] = useState("")
    const fileType = useRef(null)
    const fileAspectRatio = useRef(null)
    const gifDurationRef = useRef(null)
    const [ fileContent, setFileContent ] = useState(null) 
    const [ fileIsValid, setFileIsValid ] = useState(false)

    const [ canAdd, setCanAdd ] = useState(false)

    const gifDuration = ( content ) => {
        const arrayBuffer = new ArrayBuffer(content.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < content.length; i++) {
            uint8Array[i] = content.charCodeAt(i);
        }

        const gif = new parseGIF(arrayBuffer);
        const frames = decompressFrames(gif,true)

        // Calculate total duration
        let totalDuration = 0;
        frames.forEach(frame => {
            totalDuration += frame.delay; // delay is in hundredths of a second
        });

        return totalDuration;
    }


    useEffect( () => {
        if( showModal )
        {
            setValue("")
            setFileContent(null)
            fileType.current = null
            fileAspectRatio.current = null
            setFileIsValid(false)
            setCanAdd(false)
            //Should reload
        }
    },[showModal])

    const handleFileChange = ( event ) => {
        const file = event.target.files[0]

        if( !file )
        {
            return
        }

        
        
        fileType.current = file.type + ";base64";
        const reader = new FileReader()
        reader.onload = ( e ) => setFileContent(e.target.result.split(",")[1])
        reader.readAsDataURL(file)
    }

    useEffect( () => {
        if( fileContent == null )
        {
            return
        }

        const img = new Image()
        img.src = `data:${fileType.current}, ${fileContent}`
        img.onload = () => {
            const ratio = img.width / img.height
            fileAspectRatio.current = ratio.toFixed(2)
            verifyFile()
        }
    },[fileContent])

    useEffect( () => {
        if( value == "" || fileIsValid == false )
        {
            setCanAdd(false)
        }
        else
        {
            setCanAdd(true)
        }
    },[ value, fileIsValid ])

    const verifyFile = () => {
        
        for( const image of images )
        {
            if( image.aspect_ratio != fileAspectRatio.current )
            {
                alert("Image aspect ratio does not match the previous images")
                setFileIsValid(false)
                return
            }
        }
        
        setFileIsValid(true)

    }

    const verifyName = () => {
        
        for( const image of images )
        {
            if( image.value == value )
            {
                alert("Value already exists in the map")
                return false
            }
        }
        
        return true
    }

    const addFile = () => {
        if( !verifyName() ) 
        {
            return
        }

        if( fileType.current == "image/gif;base64" )
        {
            gifDurationRef.current = gifDuration( atob(fileContent) )
        }

        let image_data = {}
        image_data.content = fileContent
        image_data.type = fileType.current
        image_data.gif_duration = gifDurationRef.current
        image_data.aspect_ratio = fileAspectRatio.current
        image_data.value = value
        image_data.default = 0

        let c = structuredClone(images)
        c.push(image_data) 
        setImages(c)
    }

    const removeImage = ( idx ) => {
        let c = structuredClone(images)
        c.splice(idx,1)
        setImages(c)
    }

    const makeDefault = ( idx ) => {
        let c = structuredClone(images)

        for( let image of c )
        {
            c.default = 0
        }

        c[idx].default = 1
        setImages(c)
    }

    return (
        <Modal show={showModal} onHide={e => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Image List</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width:'100%', height:'400px', overflowY:'auto'}}>
                <div>
                    <InputGroup size="sm">
                        <InputGroup.Text>Variable value</InputGroup.Text>
                        <Form.Control style={{ width:'50px' }} value={value} onChange={ e => setValue(e.target.value)} />
                        <Form.Control type="file" onChange={handleFileChange}/>
                        <Button style={{textTransform:'none'}} disabled={!canAdd} onClick={addFile}>Add</Button>

                    </InputGroup>
                </div>
                { images &&
                <>
                { images.map( (image,idx) => 
                    <div>
                        <img src={"data:" + image.type + ";base64," + image.content} style={{ maxWidth:'50px', maxHeight:'50px' }} />

                        
                        <>{ image.value }</>
                        { image.default == 0 &&
                        <>
                        [<a href="#" onClick={e => makeDefault(idx)}>Make Default</a>]
                        </>
                        }
                        [<a href="#" onClick={e => removeImage(idx)}>Remove</a>]

                    </div>
                )}
                </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" onClick={e => setShowModal(false)} 
                        size="sm" style={{ marginRight:'5px', textTransform:'none' }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function ImagePickerProperties( props )
{
    const dynoCfg = props.dynoCfg 
    const setDynoCfg = props.setDynoCfg 
    const selected = props.selected
    const reset = props.reset
    const modalOpen = props.modalOpen

    const [ images, setImages ] = useState(null)
    const [ variables, setVariables ] = useState(null)

    const [ showModal, setShowModal ] = useState(false)

    const reload = () => {
        let properties = dynoCfg.properties
        let cfg = ( properties.image_picker_cfg ) ? properties.image_picker_cfg : {}

        setImages( (cfg.images) ? cfg.images : [] )
        setVariables( (cfg.variables) ? cfg.variables : {} )
    }

    useEffect(() => {
        reload()
    },[])

    useEffect(() => {
        if( images == null || variables == null )
        {
            return
        }

        let data = {}
        data.images = images
        data.variables = variables

        let cfg = structuredClone(dynoCfg)
        cfg.properties.image_picker_cfg = data

        if( images.length > 0 )
        {
            const aspect_ratio = images[0].aspect_ratio

            let cfg = structuredClone(dynoCfg)
            let w = cfg.location.width 
            let h = w/aspect_ratio 

            cfg.location.height = h
        }

        setDynoCfg(cfg)

    },[ images, variables ])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])

    return (
        <>
            <SettingsGroup name="Data source">
                <Button onClick={ e => setShowModal(true) }
                            size="sm" 
                            style={{ width:'100%', textTransform:'none' }}
                            size="small"
                    > 
                        Edit image list
                </Button>
            </SettingsGroup>

            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={imagePickerVariables} 
                            reset={reset}
            />
            }

            <ImageListModal showModal={showModal}
                            setShowModal={setShowModal}
                            images={images}
                            setImages={setImages}
            />
        </>
    )

}

